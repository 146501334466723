// useCMSStore are the same across projects,
// however they provide the junction to each project's unique data structures in neighboring directories
import { ref, Ref, computed, ComputedRef } from 'vue'
import { cmsRequest } from '../api/cmsService'
import { useAppStore } from './useAppStore'
import { useUserStore } from './useUserStore'
import {
  CmsGQLQuery,
  CmsGQLData,
  Common,
  CommonCMS,
  ConsentFormData,
  ConsentForm,
  LandingPageData,
  LandingPage,
  NudgeSchema,
  NudgeSchemaData,
  /* NudgeSchemaData, */
} from '@/types/main'
// Squidex Query strings
import consentQuery from './graphql/consentQuery.gql'
import textsQuery from './graphql/textsQuery.gql'
import landingQuery from './graphql/landingQuery.gql'
import nudgesQuery from './graphql/nudgesQuery.gql'
import { BrowserLanguageCodes, BLCToSLC } from '@/constants'

const { getters: appGetters, actions: appActions } = useAppStore()
const { getters: userGetters } = useUserStore()

// ------------  State (internal) --------------
interface State {
  consentForm: ConsentForm | undefined
  common: Common | undefined
  landingpage: LandingPage | undefined
  nudgeSchemas: NudgeSchema[]
}

const state: Ref<State> = ref({
  consentForm: undefined,
  common: undefined,
  landingpage: undefined,
  nudgeSchemas: [],
})
// ------------  Internal functions ------------

async function fetchConsent(language: BrowserLanguageCodes): Promise<CmsGQLQuery> {
  const query = consentQuery.loc.source.body as string
  const suidexLanguage = BLCToSLC[language]
  const variables = {
    __language: suidexLanguage,
    __userKind: userGetters.myUser.value.profile.kind,
  }
  return cmsRequest(query, variables, language)
}

async function fetchLandingPage(language: BrowserLanguageCodes): Promise<CmsGQLQuery> {
  const query = landingQuery.loc.source.body as string
  const suidexLanguage = BLCToSLC[language]
  const variables = {
    __language: suidexLanguage,
  }
  return cmsRequest(query, variables, language)
}

async function fetchTexts(language: BrowserLanguageCodes): Promise<CmsGQLQuery> {
  const query = textsQuery.loc.source.body as string
  const suidexLanguage = BLCToSLC[language]
  const variables = {
    __language: suidexLanguage,
  }
  return cmsRequest(query, variables, language)
}

async function fetchNudges(language: BrowserLanguageCodes): Promise<CmsGQLQuery> {
  const query = nudgesQuery.loc.source.body as string
  const suidexLanguage = BLCToSLC[language]
  const variables = {
    __language: suidexLanguage,
  }
  return cmsRequest(query, variables, language)
}

// ------------  Getters --------------
interface Getters {
  consentForm: ComputedRef<ConsentForm | undefined>
  common: ComputedRef<Common | undefined>
  landingpage: ComputedRef<LandingPage | undefined>
  nudges: ComputedRef<NudgeSchema[] | undefined>
}

const getters = {
  get consentForm(): ComputedRef<ConsentForm | undefined> {
    return computed(() => state.value.consentForm)
  },
  get common(): ComputedRef<Common | undefined> {
    return computed(() => state.value.common)
  },
  get landingpage(): ComputedRef<LandingPage | undefined> {
    return computed(() => state.value.landingpage)
  },
  get nudges(): ComputedRef<NudgeSchema[] | undefined> {
    return computed(() => state.value.nudgeSchemas)
  },
}

// ------------  Actions --------------
interface Actions {
  getConsentForm: () => Promise<void>
  getTexts: () => Promise<void>
  getLandingpage: () => Promise<void>
  getAllNudgeSchemas: () => Promise<NudgeSchema[] | undefined>
  getNudgeSchema: (id: string) => NudgeSchema | undefined
}
const actions = {
  // Get a NudgeSchema from the local store based on its ID
  getNudgeSchema: (id: string): NudgeSchema | undefined => {
    return state.value.nudgeSchemas.find((n) => n._id === id)
  },
  // Retrieve from CMS the Consent data
  getConsentForm: async function (): Promise<void> {
    const language = appGetters.languageCode.value
    const response: CmsGQLQuery = await fetchConsent(language) // This process will use the current selected languageCode
    if (response.data) {
      const results = response.data.results as CmsGQLData[]
      if (results.length > 0) {
        const consentData = results[0].data as ConsentFormData
        state.value.consentForm = new ConsentForm(consentData, language)
      }
    } else {
      const error = 'Consent query contains no records'
      appActions.setFeedbackMessage(error)
      console.log(error)
    }
    return Promise.resolve()
  },

  // Retrieve from CMS the Landing page data
  getLandingpage: async function (): Promise<void> {
    const language = appGetters.languageCode.value
    appActions.setLoading(true)
    const response: CmsGQLQuery = await fetchLandingPage(language) // This process will use the current selected languageCode
    if (response.data) {
      const results = response.data.results as CmsGQLData[]
      if (results.length > 0) {
        const landingpageData = results[0] as LandingPageData
        state.value.landingpage = new LandingPage(landingpageData, language)
      }
    } else {
      const error = 'Consent query contains no records'
      appActions.setFeedbackMessage(error)
      console.log(error)
    }
    appActions.setLoading(false)
    return Promise.resolve()
  },

  // Retrieve from CMS all translations of texts.
  getTexts: async function (): Promise<void> {
    const language = appGetters.languageCode.value
    appActions.setLoading(true)
    const response: CmsGQLQuery = await fetchTexts(language)
    if (response.data) {
      state.value.common = new Common(response.data.results as CommonCMS[])
    } else {
      const error = 'Sett query contains no records'
      appActions.setFeedbackMessage(error)
      console.log(error)
    }
    appActions.setLoading(false)
    return Promise.resolve()
  },

  // Retrieve from CMS all nudges
  getAllNudgeSchemas: async function (): Promise<NudgeSchema[] | undefined> {
    const language = appGetters.languageCode.value
    const response: CmsGQLQuery = await fetchNudges(language)
    if (response.data) {
      const results = response.data.results as CmsGQLData[]
      state.value.nudgeSchemas = []
      results.forEach((r) => {
        const data = r.data as NudgeSchemaData
        if (r.id && data) {
          const nudgeData = { ...data, id: r.id }
          state.value.nudgeSchemas.push(new NudgeSchema(nudgeData as NudgeSchemaData, language))
        }
      })
    } else {
      const error = 'Sett query contains no records'
      appActions.setFeedbackMessage(error)
      console.log(error)
    }
    return Promise.resolve(state.value.nudgeSchemas)
  },
}

// This defines the interface used externally
interface ServiceInterface {
  actions: Actions
  getters: Getters
}
export function useCMSStore(): ServiceInterface {
  return {
    getters,
    actions,
  }
}

export type CMSStoreType = ReturnType<typeof useCMSStore>
// export const UserKey: InjectionKey<UseUser> = Symbol('UseUser')
