/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */
import { ref, Ref, computed, ComputedRef } from 'vue'
import cordovaService from '../api/cordovaService'
import { emitError, uuid } from '../utilities'
import { cordovaConstants, MediaType } from '../constants'
import { CordovaDataType, CordovaData } from '@/types/main'
type Timer = ReturnType<typeof setTimeout>

// ------------  State (internal) --------------
interface CordovaState {
  deviceReady: boolean
  deviceOnline: boolean
  recordingNow: boolean
  cordovaPath: string[]
  currentVideo: FileEntry | undefined
  currentAudio: FileEntry | undefined
  currentAudioFilename: string
  currentVideoFilename: string
}

let mediaStartTime = 0 // Used to know how long the recording has been running (media.getDuration is sometimes innacurate)
let mediaTimeout: Timer // Use this to stop the media if it has run too long

const _cordovaState: Ref<CordovaState> = ref({
  deviceReady: false,
  deviceOnline: window.navigator.onLine,
  recordingNow: false,
  cordovaPath: [],
  currentVideo: undefined,
  currentAudio: undefined,
  currentAudioFilename: '',
  currentVideoFilename: '',
})

interface Getters {
  directoryPath: ComputedRef<string[]>
  deviceOnline: ComputedRef<boolean>
  deviceReady: ComputedRef<boolean>
  currentVideoFile: ComputedRef<FileEntry | undefined>
  currentAudioFile: ComputedRef<FileEntry | undefined>
  audioFilename: ComputedRef<string>
  videoFilename: ComputedRef<string>
}
interface Actions {
  setup: () => void
  recordVideo: (filename?: string, recordingCompleted?: () => void) => void
  loadMedia: (filename: string, type: MediaType) => Promise<void | FileEntry>
  removeMedia: (filename: string, type: MediaType) => Promise<void>
  createAudio: (audioFilename?: string) => Promise<void>
  startRecordingAudio: () => Promise<void>
  stopRecordingAudio: () => Promise<void>
  pauseRecordingAudio: () => Promise<void>
  resumeRecordingAudio: () => Promise<void>

  // In this store, the path is set to locate resources e.g. video / audio recordings
  setCordovaPath: (path: string[]) => void

  // Another Store should call these to have its data saved or loaded
  loadFromStorage: <T>(cordovaData: CordovaData) => Promise<T | void>
  saveToStorage: (cordovaData: CordovaData) => Promise<void>
  removeFromStorage: (cordovaData: CordovaData) => Promise<void>
  copyFileToTemp: (cordovaData: CordovaData) => Promise<FileEntry | void>
  getCachedMedia: (fileURL: string) => Promise<ArrayBuffer | string>
  loadMediaCache: () => Promise<void>
  saveMediaCache: () => Promise<void>

  // Called from an event listener to log errors to drive
  logErrorMessage: (errorText: string) => void
}
// This defines the interface used externally
interface ServiceInterface {
  actions: Actions
  getters: Getters
}
function useDeviceService(): ServiceInterface {
  // ------------ Internal fucntions --------------

  // Handle the device losing internet connection
  function onOffline() {
    _cordovaState.value.deviceOnline = false
  }
  function onOnline() {
    _cordovaState.value.deviceOnline = true
  }

  // ------------  Getters (Read only) --------------

  // Node: these are 'getters' which should be called as a variable, not a function
  const getters = {
    get directoryPath(): ComputedRef<string[]> {
      return computed(() => _cordovaState.value.cordovaPath)
    },
    get deviceOnline(): ComputedRef<boolean> {
      return computed(() => _cordovaState.value.deviceOnline)
    },
    get deviceReady(): ComputedRef<boolean> {
      return computed(() => _cordovaState.value.deviceReady)
    },
    get currentVideoFile(): ComputedRef<FileEntry | undefined> {
      return computed(() => _cordovaState.value.currentVideo)
    },
    get currentAudioFile(): ComputedRef<FileEntry | undefined> {
      return computed(() => _cordovaState.value.currentAudio)
    },
    get audioFilename(): ComputedRef<string> {
      return computed(() => _cordovaState.value.currentAudioFilename)
    },
    get videoFilename(): ComputedRef<string> {
      return computed(() => _cordovaState.value.currentVideoFilename)
    },
  }
  // ------------  Actions --------------

  const actions: Actions = {
    setup: function (): void {
      _cordovaState.value.deviceReady = true
      document.addEventListener('offline', onOffline, false)
      document.addEventListener('online', onOnline, false)
      cordovaService.checkPermissionList()
    },
    logErrorMessage: function (errorText: string): void {
      if (_cordovaState.value.deviceReady) cordovaService.saveLog(errorText)
    },
    // Set the location for device data (e.g. recordings)
    setCordovaPath: function (path: string[]): void {
      _cordovaState.value.cordovaPath = path
    },
    // This will look in the Participant's folder for a file and load it to the state.currentVideo.
    loadMedia: function (fileName: string, type: MediaType): Promise<FileEntry | void> {
      const cd: CordovaData = new CordovaData({
        fileName,
        readFile: false,
        path: _cordovaState.value.cordovaPath,
      })
      return this.loadFromStorage<FileEntry>(cd).then((fileEntry: FileEntry | void) => {
        if (fileEntry && type === MediaType.video) {
          _cordovaState.value.currentVideoFilename = cd.fileName
          _cordovaState.value.currentVideo = fileEntry
          _cordovaState.value.recordingNow = false
        } else if (fileEntry && type === MediaType.audio) {
          _cordovaState.value.currentAudioFilename = cd.fileName
          _cordovaState.value.currentAudio = fileEntry
          _cordovaState.value.recordingNow = false
        }
      })
    },
    removeMedia: function (fileName: string, type: MediaType): Promise<void> {
      const cd: CordovaData = new CordovaData({
        fileName,
        readFile: false,
        path: _cordovaState.value.cordovaPath,
      })
      return this.removeFromStorage(cd).then(() => {
        if (type === MediaType.video) {
          _cordovaState.value.currentVideoFilename = ''
          _cordovaState.value.currentVideo = undefined
          _cordovaState.value.recordingNow = false
        } else if (type === MediaType.audio) {
          _cordovaState.value.currentAudioFilename = ''
          _cordovaState.value.currentAudio = undefined
          _cordovaState.value.recordingNow = false
        }
      })
    },
    // Begin a video recording session - this will call the OS Camera module and resolve when that module returns with a finished video
    // Then the video is moved from the temp folder to a more suitable location
    recordVideo: function (filename?: string, recordingCompleted?: () => void): Promise<void> {
      if (!_cordovaState.value.deviceReady) {
        const e = new Error('Cordova not ready calling recordVideo')
        e.name = 'Warning'
        emitError(e)
        return Promise.resolve()
      }
      if (_cordovaState.value.recordingNow) {
        const e = new Error('Called recordVideo when already recording')
        e.name = 'Warning'
        emitError(e)
        return Promise.resolve()
      }
      _cordovaState.value.recordingNow = true
      return cordovaService
        .captureVideo()
        .then((mediaFile: MediaFile | void) => {
          // The returned file is in the temp directory
          if (mediaFile) {
            const fileId = filename || uuid()
            const cordovaData: CordovaData = new CordovaData({
              fileName: fileId + '.mp4',
              fileToMove: mediaFile,
              path: _cordovaState.value.cordovaPath,
            })
            // This will move a file from a temp directory to our app storage
            cordovaService.moveMediaFromTemp(cordovaData).then((fileEntry: FileEntry | void) => {
              if (fileEntry) {
                // What was a MediaFile will now be a FileEntry
                _cordovaState.value.currentVideoFilename = cordovaData.fileName || 'videoFilenameNotFound'
                _cordovaState.value.currentVideo = fileEntry
                _cordovaState.value.recordingNow = false
                if (recordingCompleted) recordingCompleted()
              }
            })
          } else {
            // 'cancel' was pressed in the iOS video recorder..
            _cordovaState.value.recordingNow = false
            if (recordingCompleted) recordingCompleted()
          }
        })
        .catch((error: Error) => console.log(error))
    },

    // Create a new audio Media and set it as the current audio
    // Reference: https://cordova.apache.org/docs/en/10.x/reference/cordova-plugin-media/
    createAudio: function (audioFilename?: string): Promise<void> {
      if (!_cordovaState.value.deviceReady) {
        const e = new Error('Cordova not ready calling recordAudio')
        e.name = 'Warning'
        emitError(e)
        return Promise.resolve()
      }
      if (_cordovaState.value.recordingNow) {
        const e = new Error('Called recordAudio when already recording')
        e.name = 'Warning'
        emitError(e)
        return Promise.resolve()
      }
      _cordovaState.value.recordingNow = true
      const cordovaData: CordovaData = new CordovaData({
        fileName: (audioFilename || uuid()) + '.m4a', // iOS only records to files of type .wav and .m4a
        // path: [], // the recording will be placed in the application's documents/tmp directory
      })
      return cordovaService
        .createAudio(cordovaData)
        .then(() => {
          _cordovaState.value.currentAudioFilename = cordovaData.fileName
        })
        .catch((error: Error) => {
          _cordovaState.value.recordingNow = false
          console.log(error)
        })
    },
    // Start recording with the current audio object
    startRecordingAudio: function (): Promise<void> {
      return cordovaService.startRecordingAudio().then(() => {
        _cordovaState.value.recordingNow = true
        console.log('Started audio recorder')
        mediaStartTime = Date.now()
        // Create a timer to stop the recording if it exceeds the maximum duration
        clearTimeout(mediaTimeout)
        mediaTimeout = setTimeout(() => {
          if (_cordovaState.value.recordingNow) {
            this.stopRecordingAudio()
          }
        }, cordovaConstants.audioRecordingMaxDuration)
      })
    },
    pauseRecordingAudio: function (): Promise<void> {
      return cordovaService.pauseRecordingAudio()
    },
    resumeRecordingAudio: function (): Promise<void> {
      return cordovaService.resumeRecordingAudio()
    },
    stopRecordingAudio: function (): Promise<void> {
      return cordovaService
        .stopRecordingAudio()
        .then(() => {
          clearTimeout(mediaTimeout)
          if (!_cordovaState.value.recordingNow) return
          if (mediaStartTime === 0) return
          const mediaLength = Date.now() - mediaStartTime
          // The returned file is in the temp directory
          // Don't use it if it was too short..
          // Audio recording should be 2 seconds minimum
          if (mediaLength < 2000) {
            _cordovaState.value.currentAudioFilename = ''
            _cordovaState.value.currentAudio = undefined
          } else {
            const cordovaData: CordovaData = new CordovaData({
              fileName: _cordovaState.value.currentAudioFilename,
              path: _cordovaState.value.cordovaPath, // This path should have been set to the current Participant's directory
            })
            // This will move the audio file from temp directory to our desired storage
            return cordovaService
              .moveMediaFromTemp(cordovaData)
              .then((movedFile: FileEntry | void) => {
                if (movedFile) {
                  console.log(`Audio file moved to: ${movedFile.toInternalURL()}`)
                  // What was a MediaFile will now be a FileEntry
                  _cordovaState.value.currentAudioFilename = cordovaData.fileName || ''
                  _cordovaState.value.currentAudio = movedFile
                  console.log('Stopped audio recorder')
                }
                _cordovaState.value.recordingNow = false
              })
              .catch((error: Error) => console.log(error))
          }
        })
        .catch((error: Error) => console.log(error))
    },
    /**
     * Load a file given a CordovaData config object
     *
     * e.g.
     * CordovaData {
     *    filename: string
     *    path: string[]
     *    readFile: true  <== Returns the content if true, returns a FileEntry if false
     *    type: 'text' if read as text, otherwise read as binary
     *    asJSON: true <== Use false to read a text file as raw text
     * }
     * Returns a promise
     */
    loadFromStorage: function <T>(cordovaData: CordovaData): Promise<T | void> {
      if (!_cordovaState.value.deviceReady) {
        const e = new Error('Cordova not ready calling loadFromStorage')
        e.name = 'Warning'
        emitError(e)
        return Promise.resolve()
      }
      return cordovaService.loadFromStorage<T>(cordovaData)
    },
    /* saveToStorage
     * Save data to device. Include a 'data' object inside CordovaData, this will be serialised
     *
     * e.g.
     * CordovaData {
     *    data: Participant dict. || KMTracking dict.
     *    filename: string
     *    path: string[]
     * }
     * Returns a promise
     */
    saveToStorage: function (cordovaData: CordovaData): Promise<void> {
      if (!_cordovaState.value.deviceReady) {
        const e = new Error('Cordova not ready calling saveToStorage')
        e.name = 'Warning'
        emitError(e)
        return Promise.resolve()
      }
      return cordovaService.saveToStorage(cordovaData)
    },
    /* removeFromStorage
     * Remove a file from device
     *
     * e.g.
     * CordovaData {
     *    filename: string
     *    path: string[]
     * }
     * Returns a promise
     */
    removeFromStorage: function (cordovaData: CordovaData): Promise<void> {
      if (!_cordovaState.value.deviceReady) {
        const e = new Error('Cordova not ready calling saveToStorage')
        e.name = 'Warning'
        emitError(e)
        return Promise.resolve()
      }
      return cordovaService.removeFromStorage(cordovaData)
    },
    /* copyFileToTemp
     * Make a copy of the file in the application /tmp directory
     *
     * Resolves:
     *  copied File object
     *  or <void> + emit an error if there was an unexpected result
     */
    copyFileToTemp: function (cordovaData: CordovaData): Promise<FileEntry | void> {
      if (!_cordovaState.value.deviceReady) {
        emitError(new Error('Cordova not ready calling copyFileToTemp'))
        return Promise.resolve()
      }
      return cordovaService.copyFileToTemp(cordovaData)
    },
    /* getCachedMedia
     * Cache a local copy of the file in the application cache directory
     *
     * Resolves:
     *  blob containing local file OR URl to remote image if not found
     */
    getCachedMedia: async function (fileURL): Promise<ArrayBuffer | string> {
      if (!_cordovaState.value.deviceReady) return Promise.resolve(fileURL)
      const media = await cordovaService.getFileFromCache(fileURL)
      // If we didn't find a cahced file, attempt to add this URL to the cache
      if (typeof media === 'string') {
        cordovaService.downloadFileToCache(fileURL)
      }
      return media
    },
    loadMediaCache: function (): Promise<void> {
      if (!_cordovaState.value.deviceReady) return Promise.resolve()
      return cordovaService.loadMediaCache()
    },
    saveMediaCache: function (): Promise<void> {
      if (!_cordovaState.value.deviceReady) return Promise.resolve()
      return cordovaService.saveMediaCache()
    },
  }

  return {
    actions,
    getters,
  }
}

export type DeviceServiceType = ReturnType<typeof useDeviceService>
export type { CordovaState, CordovaData, CordovaDataType }
export default useDeviceService
//export const AppKey: InjectionKey<UseApp> = Symbol('UseApp')
