<template>
  <div class="w-full h-16 flex flex-wrap items-center sm:justify-center">
    <div class="w-full absolute z-50 px-5 font-bold">Språksterk</div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'AppHeaderComponent',

    components: {},
    setup() {
      return {}
    },
  })
</script>

<style scoped></style>
