<template>
  <div class="relative flex flex-wrap flex-row justify-center items-center bg-blue-button" style="min-height: 100%">
    <!--   <iframe
      tabindex="1"
      id="iframe"
      class="nettskjema-iframe"
      width="100%"
      frameborder="0"
    ></iframe> -->
    <div class="w-full h-auto flex flex-col pt-12 select-none">
      <img
        class="rounded-md mx-auto"
        style="max-height: 150px; max-width: 150px"
        src="../../assets/spraksterk-socialmedia.png"
        alt="Språksterk logo"
      />
      <!-- eslint-disable vue/no-v-html -->
      <div
        v-if="landingpage && landingpage.content"
        class="h-1/2 text-white mx-auto text-center"
        style="max-width: 400px"
        v-html="landingpage.content"
      ></div>
    </div>

    <div class="h-auto">
      <transition class="" mode="out-in" name="fade">
        <div v-if="mode === 'smsLogin'" key="landing-mobil" class="flex flex-col items-center h-full justify-center pb-12">
          <div class="flex flex-col">
            <AnswerInput
              v-model="mobileNumber"
              class="m-2"
              mode="tel"
              :label="t('enterMobilNumber')"
              :border="true"
              @enterkey="sendPhoneNumber()"
            ></AnswerInput>
            <!-- <AnswerInput
              class="m-2"
              mode="password"
              :label="t('enterPass')"
              :border="true"
              v-model="pinCode"
              @enterkey="sendPhoneNumber()"
            ></AnswerInput> -->
            <SlButton id="button-send" class="mt-10 capitalize" background-class="bg-green-highlight" @click="sendPhoneNumber"
              >{{ t('send') }}
            </SlButton>
            <SlButton
              v-if="showTestLogin"
              id="button-admin"
              class="my-2 capitalize"
              background-class="bg-grey"
              text-class="text-black"
              @click="() => (mode = 'login')"
              >admin
            </SlButton>
          </div>
        </div>
        <div v-else-if="mode === 'smsCode'" key="landing-otc" class="flex flex-col items-center h-full justify-center pb-12">
          <div class="flex flex-col">
            <AnswerInput v-model="otc" class="m-2" mode="otc" :label="t('enterMobilOTC')" :border="true" @enterkey="sendOTC()"></AnswerInput>
            <SlButton id="button-send" class="mt-16" background-class="bg-green-highlight" @click="sendOTC">{{ t('send') }} </SlButton>
            <SlButton
              id="button-back"
              class="my-2"
              background-class="bg-grey"
              text-class="text-black"
              @click="() => ((pinCode = ''), (otc = ''), (mode = 'smsLogin'))"
              >{{ t('back') }}
            </SlButton>
          </div>
        </div>
        <div v-else-if="mode === 'login'" key="landing-main" class="flex flex-col items-center h-full justify-center pb-12">
          <SlButton v-if="filteredLocalUsersWithExpiry.length > 0" id="button-pinlogin" class="my-2" @click="PINlogin()">Log in with PIN </SlButton>
          <SlButton class="my-2" background-class="bg-green-highlight" :background-colour="'bg-green-highlight'" @click="DPlogin()"
            >Log in with ID FEIDE
          </SlButton>
          <SlButton
            v-if="showTestLogin"
            id="button-testuser"
            class="my-2"
            background-class="bg-white"
            text-class="text-black"
            @click="DPlogin(true, 'testuser')"
            >Test User - pedleder
          </SlButton>
          <SlButton
            v-if="showTestLogin"
            id="button-testuser2"
            class="my-2"
            background-class="bg-white"
            text-class="text-black"
            @click="DPlogin(true, 'testuser2')"
            >Test User - foresatt
          </SlButton>
          <SlButton id="button-admin" class="my-2" background-class="bg-grey" text-class="text-black" @click="() => (mode = 'smsLogin')"
            >{{ t('back') }}
          </SlButton>
        </div>
        <div v-else-if="mode === 'pin-user'" key="landing-pin-user" class="flex flex-col items-center h-full justify-center">
          <p class="text-white">Log in as</p>
          <div
            v-for="u in filteredLocalUsersWithExpiry"
            :key="u._id"
            class="mt-8 rounded-full w-full bg-white flex flex-col items-center p-4"
            @click="selectUser(u)"
          >
            <p>{{ u.name }}</p>
            <p class="text-xs">Expires in {{ u.expiresIn }}</p>
          </div>
        </div>
        <div v-else-if="mode === 'pp'" key="landing-pin-pin" class="flex flex-col items-center h-full justify-center">
          <p class="text-white">What's your PIN code?</p>
          <AnswerInput v-model="pinCode" mode="text" class="mt-8" />
          <SlButton text="login" class="mt-8 focus:outline-none" @click="localUserLogin()" />
        </div>
      </transition>
    </div>
    <p class="absolute bottom-0 right-0 text-xs m-2">v{{ appVersion }}</p>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, ref, Ref, onBeforeUnmount, onMounted } from 'vue'
  import router from '@/router'
  import moment from 'moment'
  import { useI18n } from 'vue-i18n'
  import SlButton from '@/components/base/SlButton.vue'
  import AnswerInput from '@/components/base/AnswerInput.vue'
  import { baseUrl, appVersion, BrowserLanguageCodes } from '../../constants'
  import { useAppStore } from '../../store/useAppStore'
  import { useCMSStore } from '../../store/useCMSStore'
  import useDeviceService from '../../composition/useDevice'
  import { LocalUser } from '../../types/main'

  const jwtExpiryConstant = import.meta.env.VITE_JWT_EXPIRY || '0'
  const jwtExpiry = Number.parseInt(jwtExpiryConstant)

  const messages = {
    nb_NO: {
      enterMobilNumber: 'Mobilnummer',
      enterPass: 'Ditt passord',
      enterMobilOTC: 'Engangskode (fra SMS)',
      missing: 'Mangler mobilnummer eller passord',
      'User not found': 'Bruker ikke funnet',
      'LOGIN: Incorrect password': 'Feil passord',
      'wrong otc': 'Feil engangspassord',
      'sms login accepted': 'Det kan ta litt tid før du får engangskode med SMS.',
      send: 'send',
      back: 'back',
    },
    en: {
      enterMobilNumber: 'Mobile phone number',
      enterPass: 'Your password',
      enterMobilOTC: 'One time code (from SMS)',
      missing: 'Missing phone number or password',
      'User not found': 'User not found',
      'LOGIN: Incorrect password': 'Incorrect password',
      'wrong otc': 'Wrong one time code',
      'sms login accepted': 'It may take some time before you get the one time code by SMS.',
      send: 'send',
      back: 'back',
    },
  }

  interface LocalUserWithExpiry extends LocalUser {
    expiresIn: string
    valid: boolean
  }

  export default defineComponent({
    name: 'LandingComponent',
    components: {
      SlButton,
      AnswerInput,
    },
    setup() {
      const { t, locale } = useI18n({ messages })
      const { getters: appGetters, actions: appActions } = useAppStore()
      const { getters: cmsGetters, actions: cmsActions } = useCMSStore()
      const { getters: deviceGetters } = useDeviceService()
      const mode = ref('smsLogin')
      const pinCode = ref('')
      const mobileNumber = ref('')
      const otc = ref('')
      const showTestLogin = ref(false)
      const selectedUser = ref()
      const localUsers = Object.values(appGetters.persistedLocalUsers.value)
      const landingpage = computed(() => cmsGetters.landingpage.value)
      /*  const NBList = ['nb-NO', 'nb-NO', 'no']
    const NNList = ['nn-NO', 'nn_NO', 'nn']
    const ENList = ['en', "en-AU"] */

      /*  const listContainLanguage = (list: Array<string>) => {
      return list.some((lang) => lang == locale.value)
    } */

      // check of language exists
      const isValidLanguage = (lang: string) => {
        return Object.keys(BrowserLanguageCodes).some((language) => language == lang)
      }
      onMounted(() => {
        if (isValidLanguage(locale.value as string)) {
          const lang: BrowserLanguageCodes = locale.value as BrowserLanguageCodes
          appActions.setLanguageCode(lang)
        } else appActions.setLanguageCode(BrowserLanguageCodes['nb-NO'])

        // Get the landingpage from CMS
        cmsActions.getLandingpage()
        if (router.currentRoute.value.params.admin === 'admin') {
          mode.value = 'login'
        }
      })

      const localUsersWithExpiry: Ref<LocalUserWithExpiry>[] = localUsers.map((lu) => {
        const luwe = {
          ...lu,
          expiresIn: '',
          valid: false,
        }
        return ref(luwe)
      })

      function momentFormat(date: Date) {
        return moment(date).format('ddd, hA')
      }

      let timer: ReturnType<typeof setTimeout>
      const openOverlay = (message: string) => {
        clearTimeout(timer)
        appActions.setFeedbackMessage(message)
        timer = setTimeout(() => {
          appActions.setFeedbackMessage('')
        }, 3000)
      }

      const sendPhoneNumber = () => {
        /*  if (!pinCode.value || mobileNumber.value.toString().length !== 8) { */
        if (mobileNumber.value.toString().length !== 8) {
          openOverlay(t('missing'))
        } else {
          appActions
            .smsLogin({
              /* password: pinCode.value, */
              mobileNumber: mobileNumber.value.toString(),
            })
            .then(() => {
              mode.value = 'smsCode'
              openOverlay(t('sms login accepted'))
            })
            .catch((error) => {
              console.log(error)
              openOverlay(t(error.error))
            })
        }
      }

      const sendOTC = () => {
        appActions
          .smsCode({
            otc: otc.value,
          })
          .then(() => {
            router.push('/login')
          })
          .catch((error) => {
            console.log(error)
            openOverlay(t('wrong otc'))
          })
      }

      showTestLogin.value = true

      function PINlogin() {
        mode.value = 'pin-user'
      }

      function DPlogin(testing = false, username = 'testuser') {
        let feideLoginUrl = `${baseUrl}/auth/dataporten/login`
        // If using Cordova, activate the cordova-oauth plugin
        feideLoginUrl += `?device=webApp${testing ? `&testing=${username}` : ''}`
        window.location.href = feideLoginUrl
        // NOTE: Call OAuth without 'oauth:' name to open the system browser
        // window.OAuth('https://www.uio.no/tjenester/it/lagring-samarbeid/gsuite/', '_system', 'location=yes');
      }
      // This logs in the user without going through the FIEDE process, so long as they have a valid token
      function localUserLogin() {
        if ((selectedUser.value.pin === pinCode.value && deviceGetters.deviceOnline) || import.meta.env.NODE_ENV == 'development') {
          selectedUser.value.lastLogin = new Date()
          appActions.setCurrentLocalUser(selectedUser.value)
          appActions.tokenLogin().then((success: boolean) => {
            if (!success) mode.value = 'login'
          })
        }
      }

      function selectUser(u: LocalUser) {
        localUsers.forEach((u) => (u.selected = false))
        u.selected = true
        selectedUser.value = u
        mode.value = 'pp'
      }

      const calculateCountdown = () => {
        const dateNow = new Date()
        localUsersWithExpiry.forEach((luRef: Ref<LocalUserWithExpiry>) => {
          const lu = luRef.value
          const countdown = jwtExpiry - moment(dateNow).diff(lu.lastLogin, 'seconds')
          lu.expiresIn = moment.duration(countdown, 'seconds').humanize()
          lu.valid = countdown > 0
        })
      }
      calculateCountdown()
      const countDownInterval = setInterval(() => {
        calculateCountdown()
      }, 10000)
      onBeforeUnmount(() => {
        clearInterval(countDownInterval)
      })

      const filteredLocalUsersWithExpiry = computed(() => localUsersWithExpiry.filter((u) => u.value.valid).map((u) => u.value))

      return {
        t,
        status: appGetters.status,
        filteredLocalUsersWithExpiry,
        deviceReady: deviceGetters.deviceOnline,
        appVersion,
        momentFormat,
        showTestLogin,
        pinCode,
        mobileNumber,
        otc,

        selectUser,
        sendPhoneNumber,
        sendOTC,
        DPlogin,
        PINlogin,
        localUserLogin,
        mode,
        selectedUser,
        landingpage,
      }
    },
  })
</script>

<style scoped></style>
