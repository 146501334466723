<template>
  <div class="relative w-screen h-screen overflow-hidden z-0">
    <AppHeaderComponent class="sm:hidden" />
    <HamburgerMenu :list="menuButtons" :title="user.profile.fullName" @button-clicked="hamburgerMenuItemClicked" />

    <!-- Show nudges or others here -->
    <div class="sm:block" style="max-width: 800px">
      <router-view></router-view>
    </div>
  </div>
</template>

<script setup lang="ts">
  // @ is an alias to /src
  import { computed, ref, onMounted } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { apiRequest } from '@/api/apiRequest'
  import { useAppStore } from '@/store/useAppStore'
  import { useUserStore } from '@/store/useUserStore'
  import { useCMSStore } from '@/store/useCMSStore'
  import { useTableStore, TableType } from '@/store/useTableStore'
  import useDeviceService from '@/composition/useDevice'
  import router from '@/router'
  import HamburgerMenu from '@/components/base/HamburgerMenu.vue'
  import { USER_ROLE, HamburgerMenuEvent } from '@/constants'
  import { XHR_REQUEST_TYPE, Participant } from '@/types/main'

  import AppHeaderComponent from '@/components/AppHeaderComponent.vue'

  const messages = {
    ar: {},
    bs: {},
    nn_NO: {},
    nb_NO: {
      enrollUsers: 'Registrering',
      myConsent: 'Mitt samtykke',
      logout: 'Logg ut',
      monitor: 'Monitor',
      nudges: 'Mine dulter',
    },
    en: {
      enrollUsers: 'Enrollment',
      myConsent: 'My consent',
      logout: 'Log out',
      monitor: 'Monitor',
      nudges: 'My nudges',
    },
    ku: {},
    pl: {},
    so: {},
    sq: {},
    ta: {},
    tr: {},
    ur: {},
    vi: {},
  }

  const { t } = useI18n({ messages })
  const { actions: appActions } = useAppStore()
  const { actions: cmsActions } = useCMSStore()
  const { getters: userGetters, actions: userActions } = useUserStore()
  const { actions: tableActions } = useTableStore()
  const { actions: deviceActions } = useDeviceService()
  const hasMonitorRole = computed(() => userActions.hasMinimumRole(userGetters.myUser.value, USER_ROLE.monitor))
  /* const isEnroller = computed(
      () =>
        userGetters.myUser.value.profile.kind === USER_KIND.styrer ||
        userGetters.myUser.value.profile.kind == USER_KIND.pedleder
    ) */
  // Mounted
  onMounted(() => {
    cmsActions.getAllNudgeSchemas()
    tableActions.getAll<Participant>(TableType.participants)
  })
  const menuButtons = [
    { title: t('nudges'), event: HamburgerMenuEvent.NUDGES },
    { title: t('enrollUsers'), event: HamburgerMenuEvent.ENROLL },
    { title: t('myConsent'), event: HamburgerMenuEvent.CONSENT },
    { title: t('logout'), event: HamburgerMenuEvent.LOGOUT },
  ]
  if (hasMonitorRole.value) {
    menuButtons.push({ title: t('monitor'), event: HamburgerMenuEvent.MONITOR })
  }

  const hamburgerMenuItemClicked = (event: HamburgerMenuEvent) => {
    switch (event) {
      case HamburgerMenuEvent.NUDGES:
        router.push('/dashboard/nudges')
        break
      case HamburgerMenuEvent.ENROLL:
        router.push('/enroll')
        break
      case HamburgerMenuEvent.CONSENT:
        router.push('/consent')
        break
      case HamburgerMenuEvent.MONITOR:
        router.push('/monitor')
        break
      case HamburgerMenuEvent.LOGOUT:
        logout()
        break
      default:
        console.error("Can't process event from Menu")
    }
  }

  const rememberMe = ref(false)
  appActions.setFade(false)

  // --------------------------------------------------------------

  // Save media cache here, so it runs after returning from a Question series
  // Yet to determine if this is the best position to do this
  deviceActions.saveMediaCache()

  const logout = () => {
    const completeLogout = () => {
      appActions.logout(rememberMe.value)
      const admin = userGetters.myUser.value.profile.role === 'admin' ? 'admin' : ''
      router.push(`/${admin}`)
    }
    apiRequest({
      route: '/auth/logout',
      method: XHR_REQUEST_TYPE.GET,
      credentials: true,
    })
      .then(() => {
        completeLogout()
      })
      .catch((error) => {
        // Exchange was not accepted, clear the token and redirect to login page
        console.log(error)
        completeLogout()
      })
  }

  const user = userGetters.myUser
</script>
<style scoped>
  .slide-rightmenu-enter-from {
    transform-box: border-box;
    transform: translateX(100%);
  }
  .slide-rightmenu-enter-active,
  .slide-rightmenu-leave-active {
    transition: all 1s ease;
  }
  .slide-rightmenu-leave-to {
    transform-box: border-box;
    transform: translateX(100%);
  }

  .widthTransition {
    transition: transform 1s ease;
  }
  @layer {
    .-translate-x-full {
      -webkit-transform: translateX(0px);
      transform: translateX(0px);
    }

    .translate-x-0 {
      -webkit-transform: translateX(256px);
      transform: translateX(256px);
    }
  }
  /* .slidemenu-right-enter-from {
  transform: translateX(-100%);
}
.slidemenu-right-enter-active,
.slidemenu-right-leave-active {
  transition: all 1s ease;
}
.slidemenu-right-leave-to {
  transform: translateX(100%);
} */
</style>
