import { computed, ref, Ref, ComputedRef } from 'vue'
import { apiRequest } from '../api/apiRequest'

import {
  APIRequestPayload,
  XHR_REQUEST_TYPE,
  Ward,
  WardData,
  User,
  Participant,
  UserData,
  ParticipantData,
  Kindergarten,
  NudgeSchema,
  Nudge,
  NudgeData,
  NudgeMetadata,
  SendNudgeResponse,
} from '../types/main'

//  Interfaces and Enum
export enum TableType {
  wards = 'wards',
  users = 'users',
  participants = 'participants',
  nudgemetadatas = 'nudgemetadatas',
  nudges = 'nudges',
}
type RowType = Ward | User | Participant | Nudge | NudgeMetadata | undefined
type RowTypeData = WardData | UserData | ParticipantData | NudgeData | SendNudgeResponse | NudgeMetadata

enum APIRoute {
  wards = '/api/table/ward',
  users = '/api/table/user',
  participants = '/api/table/participant',
  nudges = '/api/table/nudge',
  nudgemetadatas = '/api/table/nudgemetadata',
  management_user = '/api/manage',
}
import { useCMSStore } from './useCMSStore'
const { actions: cmsActions, getters: cmsGetters } = useCMSStore()

// ------------  State (internal) --------------

interface State {
  wards: Ward[]
  wardDictionary: Map<string, Ward>
  kindergartens: Kindergarten[]
  users: User[]
  usersMap: Map<string, User>
  participants: Participant[]
  nudges: Nudge[]
  nudgemetadatas: NudgeMetadata[]
  selectedItem: Ward | User | Participant | undefined
  nudgeSchemaDictionary: Map<string, NudgeSchema>
}

const state: Ref<State> = ref({
  wards: [],
  wardDictionary: new Map<string, Ward>(),
  users: [],
  usersMap: new Map<string, User>(),
  kindergartens: [],
  participants: [],
  nudges: [],
  nudgemetadatas: [],
  selectedItem: undefined,
  nudgeSchemaDictionary: new Map<string, NudgeSchema>(),
})

// ------------  Internal functions ------------

async function fetchAll(table: TableType, query?: Record<string, string>): Promise<RowTypeData[]> {
  const payload: APIRequestPayload = {
    method: XHR_REQUEST_TYPE.GET,
    credentials: true,
    route: APIRoute[table],
    query,
  }
  return apiRequest<RowTypeData[]>(payload)
}

async function fetchPaginated(table: TableType, query?: Record<string, string>): Promise<RowTypeData[]> {
  const payload: APIRequestPayload = {
    method: XHR_REQUEST_TYPE.GET,
    credentials: true,
    route: '/api/table/nudgemetadataPaging',
    query,
  }
  return apiRequest<RowTypeData[]>(payload)
}

async function fetchOne(
  requestType: XHR_REQUEST_TYPE,
  tableType: TableType,
  data: RowType | NudgeSchema | undefined,
  id?: string,
): Promise<RowTypeData> {
  const idParam = id ? `/${id}` : ''
  const payload: APIRequestPayload = {
    method: requestType,
    credentials: true,
    route: APIRoute[tableType] + idParam,
    body: data,
  }
  return apiRequest<RowTypeData>(payload)
}

async function fetchAllWards(): Promise<WardData[]> {
  const payload: APIRequestPayload = {
    method: XHR_REQUEST_TYPE.GET,
    credentials: true,
    route: '/api/wards',
  }
  return apiRequest<WardData[]>(payload)
}

async function fetchUsers(): Promise<UserData[]> {
  const payload: APIRequestPayload = {
    method: XHR_REQUEST_TYPE.GET,
    credentials: true,
    route: '/api/users',
  }
  return apiRequest<UserData[]>(payload)
}

async function sendSMS(userId: string): Promise<UserData> {
  const payload: APIRequestPayload = {
    method: XHR_REQUEST_TYPE.POST,
    credentials: true,
    route: `/api/sms/${userId}`,
  }
  return apiRequest<UserData>(payload)
}

async function sendNudge(nudge: Nudge, query: Record<string, string>): Promise<SendNudgeResponse> {
  const payload: APIRequestPayload = {
    method: XHR_REQUEST_TYPE.POST,
    credentials: true,
    route: `/api/table/sendnudge/${nudge._id}`,
    body: nudge,
    query,
  }
  return apiRequest<SendNudgeResponse>(payload)
}

// ------------  Getters --------------

// Once a reactive getter has been gotten by a component
// we cannot overwrite its instance here in the store - but we can write to its children reactively
// Complex objects provided by a getter here should be represented by a Class and also have an update() function
interface Getters {
  allWards: ComputedRef<Ward[]>
  wardDictionary: ComputedRef<Map<string, Ward>>
  allUsers: ComputedRef<User[]>
  allUsersMap: ComputedRef<Map<string, User>>
  allParticipants: ComputedRef<Participant[]>
  allKindergartens: ComputedRef<Kindergarten[]>
  allNudges: ComputedRef<Nudge[]>
  allNudgeMetadata: ComputedRef<NudgeMetadata[]>
  selectedItem: ComputedRef<Ward | User | Participant | undefined>
  nudgeSchemaDictionary: ComputedRef<Map<string, NudgeSchema>>
}
const getters = {
  get allWards(): ComputedRef<Ward[]> {
    return computed(() => state.value.wards) // Unlikely to change during app usage, but ok as long as the array itself is not overwitten
  },
  get wardDictionary(): ComputedRef<Map<string, Ward>> {
    return computed(() => state.value.wardDictionary)
  },
  get allUsers(): ComputedRef<User[]> {
    return computed(() => state.value.users) // Unlikely to change during app usage, but ok as long as the array itself is not overwitten
  },
  get allUsersMap(): ComputedRef<Map<string, User>> {
    return computed(() => state.value.usersMap)
  },
  get allParticipants(): ComputedRef<Participant[]> {
    return computed(() => state.value.participants) // Unlikely to change during app usage, but ok as long as the array itself is not overwitten
  },
  get allKindergartens(): ComputedRef<Kindergarten[]> {
    return computed(() => state.value.kindergartens) // Unlikely to change during app usage, but ok as long as the array itself is not overwitten
  },
  get allNudges(): ComputedRef<Nudge[]> {
    return computed(() => state.value.nudges) // Unlikely to change during app usage, but ok as long as the array itself is not overwitten
  },
  get allNudgeMetadata(): ComputedRef<NudgeMetadata[]> {
    return computed(() => state.value.nudgemetadatas) // Unlikely to change during app usage, but ok as long as the array itself is not overwitten
  },
  get selectedItem(): ComputedRef<Ward | User | Participant | undefined> {
    return computed(() => state.value.selectedItem)
  },
  get nudgeSchemaDictionary(): ComputedRef<Map<string, NudgeSchema>> {
    return computed(() => state.value.nudgeSchemaDictionary)
  },
}

// ------------  Actions --------------
interface Actions {
  // Server
  getAll: <T>(table: TableType, query?: Record<string, string>) => Promise<T[]>

  getOne: (table: TableType, _id: string) => Promise<void>
  createOne: (table: TableType, row?: NudgeSchema) => Promise<RowType>
  updateOne: (table: TableType, row: RowType) => Promise<RowType>
  deleteOne: (table: TableType, row: RowType) => Promise<RowType>

  getAllWards: () => Promise<void>

  // Styrer / Pedleder
  getUsersFromWards: () => Promise<void>
  updateOneUser: (row: User) => Promise<void>
  notifyUser: (userId: string) => Promise<void>

  // Nudging
  saveNudgeSettings: (nudge: Nudge, query: Record<string, string>) => Promise<RowType>

  // Front end
  selectItem: (item: Ward | User | Participant) => Promise<void>
}

function createTableObject(table: TableType, response: RowTypeData): RowType {
  switch (table) {
    case TableType.wards:
      return new Ward(response as WardData)
    case TableType.users:
      return new User(response as UserData)
    case TableType.participants:
      return new Participant(response as ParticipantData)
    case TableType.nudges:
      return new Nudge(response as NudgeData)
    case TableType.nudgemetadatas:
      return response as NudgeMetadata
    default:
      return
  }
}

const actions = {
  // Get all Nudges and populate the templates from Squidex

  // Get all rows (objects) of a given type from Server, assign to the store
  getAll: async function <T>(table: TableType, query?: Record<string, string>): Promise<T[]> {
    const response: RowTypeData[] = await fetchAll(table, query)
    return new Promise((resolve) => {
      switch (table) {
        case TableType.wards:
          state.value.wards = (response as WardData[]).map((w) => {
            const ward = new Ward(w)
            // Add to dictionary
            state.value.wardDictionary.set(ward.skRef, ward)
            state.value.wardDictionary.set(ward.kindergartenID, ward) // Create a reference to a ward with kindergarten id
            return ward
          })
          resolve(state.value.wards as T[])
          break
        case TableType.users:
          state.value.users = (response as UserData[]).map((u) => {
            const user = new User(u)
            state.value.usersMap.set(user._id, user)
            return user
          })
          resolve(state.value.users as T[])
          break
        case TableType.participants:
          state.value.participants = (response as ParticipantData[]).map((p) => new Participant(p))
          resolve(state.value.participants as T[])
          break
        case TableType.nudges:
          {
            state.value.nudges = (response as NudgeData[]).map((n) => new Nudge(n))
            // Populate dictionary
            cmsGetters.nudges.value?.forEach((schema: NudgeSchema) => {
              state.value.nudgeSchemaDictionary.set(schema._id, schema)
            })
            const resNudges = state.value.nudges.filter(
              (nudge: Nudge) => nudge instanceof Nudge && state.value.nudgeSchemaDictionary.get(nudge.squidexID),
            )

            resolve(resNudges as T[])
          }
          break
        case TableType.nudgemetadatas:
          state.value.nudgemetadatas = response as NudgeMetadata[]
          resolve(state.value.nudgemetadatas as T[])
          break

        default:
          resolve([])
      }
      resolve([])
    })
  },

  // Get one row from Server and return it
  getOne: async function (table: TableType, _id: string): Promise<void> {
    await fetchOne(XHR_REQUEST_TYPE.GET, table, undefined, _id)
    return Promise.resolve()
  },

  // Create a new row (object) of type User | Ward | Participant at server, assign to store
  createOne: async function (table: TableType, row?: NudgeSchema): Promise<RowType> {
    const response: RowTypeData = await fetchOne(XHR_REQUEST_TYPE.POST, table, row)

    const item: RowType = createTableObject(table, response)
    if (item) {
      if (item instanceof Ward) state.value.wards.push(item)
      if (item instanceof User) state.value.users.push(item)
      if (item instanceof Participant) state.value.participants.push(item)
      if (item instanceof Nudge) state.value.nudges.push(item)
    }
    return Promise.resolve(item)
  },

  // Supply the table and row to update
  // Returns the updated row from server
  updateOne: async function (table: TableType, row: RowType): Promise<RowType | undefined> {
    if (row) {
      const response: RowTypeData = await fetchOne(XHR_REQUEST_TYPE.PUT, table, row, row._id)
      const item = createTableObject(table, response)
      if (item) {
        if (item instanceof Ward) {
          const itemToUpdate = state.value.wards.find((i: Ward) => i && item._id === i._id)
          if (itemToUpdate) {
            itemToUpdate.update(item)
            state.value.wardDictionary.set(item.skRef, item) // Update the entry
          }
        }
        if (item instanceof User) {
          const itemToUpdate = state.value.users.find((i: User) => i && item._id === i._id)
          if (itemToUpdate) itemToUpdate.update(item)
        }
        if (item instanceof Participant) {
          const itemToUpdate = state.value.participants.find((i: Participant) => i && item._id === i._id)
          if (itemToUpdate) itemToUpdate.update(item)
        }
        /*  if (TableType.nudgemetadatas == table) {
          const index = state.value.nudgemetadatas.findIndex(
            (i: NudgeMetadata) => i && item._id === i._id
          )
          if (index > -1)
            state.value.nudgemetadatas.splice(index, 1, item as NudgeMetadata)
        } */
      }
      return Promise.resolve(item)
    } else return Promise.resolve(undefined)
  },

  // Supply the table and row to delete
  // Returns void
  deleteOne: async function (table: TableType, row: RowType): Promise<RowType> {
    if (row) {
      const response: RowTypeData = await fetchOne(XHR_REQUEST_TYPE.DELETE, table, row, row._id)
      const t = state.value[table] as RowType[]
      const item = createTableObject(table, response)
      if (item) {
        const indexToDelete = t.findIndex((i) => i && item._id === i._id)
        t.splice(indexToDelete, 1)
      }
      return Promise.resolve(item)
    } else return Promise.resolve(undefined)
  },

  selectItem: async function (item: Ward | User | Participant): Promise<void> {
    if (item) {
      state.value.selectedItem = item
    }
    return Promise.resolve()
  },

  // Notify a user through sms
  notifyUser: async function (userId: string): Promise<void> {
    const response: UserData = await sendSMS(userId)
    const item: User = createTableObject(TableType.users, response) as User

    const itemToUpdate = state.value.users.find((i: User) => i && item._id === i._id)
    if (itemToUpdate) itemToUpdate.update(item)

    return Promise.resolve()
  },

  // Save Nudge settings and send nudges to users
  saveNudgeSettings: async function (nudge: Nudge, query: Record<string, string>): Promise<RowType> {
    const response: SendNudgeResponse = await sendNudge(nudge, query)
    const item: Nudge = createTableObject(TableType.nudges, response.nudge) as Nudge

    const metadatalist = response.nudgeMetadata
    if (metadatalist && metadatalist.length) {
      state.value.nudgemetadatas = state.value.nudgemetadatas.concat(metadatalist)
    }

    const itemToUpdate = state.value.nudges.find((i: Nudge) => i && item._id === i._id)
    if (itemToUpdate) itemToUpdate.update(item)
    return Promise.resolve(itemToUpdate)
  },

  // Non admin methods
  getAllWards: async function (): Promise<void> {
    // Create or update Kindergartens
    const updateKindergartens = (item: Ward) => {
      const kindergarten = state.value.kindergartens.find((kg) => kg.kindergartenID == item.kindergartenID)

      // If Kindergarten exists, add the ward to wards field
      if (kindergarten) kindergarten.wards.push(item)
      else {
        // Create a new entry
        state.value.kindergartens.push({
          kindergartenName: item.kindergartenName,
          district: item.district,
          districtType: item.districtType,
          districtID: item.districtID,
          kindergartenID: item.kindergartenID,
          wards: [],
        })
      }
    }

    fetchAllWards().then((wards) => {
      state.value.wards = []
      state.value.wards = wards.map((w) => {
        const ward = new Ward(w)
        updateKindergartens(ward)
        return ward
      })

      return Promise.resolve()
    })
  },

  getUsersFromWards: async function (): Promise<void> {
    fetchUsers().then((users) => {
      state.value.users = []
      state.value.users = users.map((u) => {
        const user = new User(u)
        return user
      })
    })
  },

  updateOneUser: async function (row: User): Promise<void> {
    if (row) {
      const idParam = row._id ? `/${row._id}` : ''
      const payload: APIRequestPayload = {
        method: XHR_REQUEST_TYPE.PUT,
        credentials: true,
        route: APIRoute.management_user + idParam,
        body: row,
      }

      const response = await apiRequest<RowTypeData>(payload)
      const item = createTableObject(TableType.users, response)
      if (item) {
        if (item instanceof User) {
          const itemToUpdate = state.value.users.find((i: User) => i && item._id === i._id)
          if (itemToUpdate) itemToUpdate.update(item)
        }
      }
    }
    return Promise.resolve()
  },
}

interface ServiceInterface {
  actions: Actions
  getters: Getters
}
// This defines the interface used externally
export function useTableStore(): ServiceInterface {
  return {
    getters,
    actions,
  }
}

export type useTableStore = ReturnType<typeof useTableStore>
