<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="flex flex-col">
    <label v-if="label" class="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2" :for="elementId" :class="{ 'ml-5': border }">
      {{ label }}
    </label>
    <p v-if="description" class="text-xs max-w-sm">{{ description }}</p>
    <template v-if="mode == 'binaryChoice'">
      <div class="flex flex-row items-center">
        <input :id="`${elementId}-yes`" v-model="selectedValue" class="mr-1 mb-1" type="radio" :value="true" @change="valueInput" />
        <label class="mr-2" :for="`${elementId}-yes`">ja</label>
      </div>
      <div class="flex flex-row items-center">
        <input :id="`${elementId}-no`" v-model="selectedValue" class="mr-1 mb-1" type="radio" :value="false" @change="valueInput" />
        <label class="mr-1" :for="`${elementId}-no`">nei</label>
      </div>
    </template>
    <template v-if="mode == 'url'">
      <input
        :id="elementId"
        v-model="selectedValue"
        type="url"
        placeholder="https://eksempel.com"
        pattern="https://.*"
        size="30"
        required
        @input="valueInput"
      />
    </template>
    <template v-if="mode == 'text' || mode === 'password' || mode === 'tel' || mode === 'email' || mode === 'number' || mode === 'otc'">
      <div
        class="w-full bg-white flex items-center"
        :class="{
          'rounded-full shadow-inner p-4': border,
          'border-gray-300 border': !border,
          'invalid-entry': !isValid && selectedValue,
        }"
      >
        <input
          :id="elementId"
          v-model="selectedValue"
          class="w-full px-1 border-gray-300 focus:ring-2 focus:ring-blue-600 bg-transparent"
          :class="{ 'border-b': underline }"
          :style="{
            width: `${customSize ? customSize + 'rem' : '100%'}`,
            height: `${parseInt(customSize) / 6}rem`,
            'font-size': `${parseInt(customSize) / 10}rem`,
          }"
          :placeholder="placeholder"
          :type="mode"
          :autocomplete="mode === 'otc' ? 'one-time-code' : ''"
          @input="valueInput"
          @keyup.enter="enterKey"
        />
      </div>
    </template>
    <template v-if="mode == 'multiChoice'">
      <div :id="elementId" class="flex flex-col">
        <div v-for="o in options" :key="o.id" class="py-1">
          <input
            :id="`${elementId}-option-${o.title.replace(/\s/g, '')}`"
            v-model="selectedMultiChoice"
            class="mr-1 mb-1"
            type="checkbox"
            :value="o.id"
            @change="valueInput"
          />
          <label class="mr-2" :for="`${elementId}-option-${o.title.replace(/\s/g, '')}`">{{ o.title }}</label>
        </div>
      </div>
    </template>
    <template v-if="mode == 'singleChoice'">
      <div :id="elementId" class="flex flex-col">
        <div v-for="o in options" :key="o.id">
          <input
            :id="`${elementId}-option-${o.title.replace(/\s/g, '')}`"
            v-model="selectedValue"
            class="mr-1 mb-1"
            type="radio"
            :value="o.title"
            @change="valueInput"
          />
          <label class="mr-2" :for="`${elementId}-option-${o.title.replace(/\s/g, '')}`">{{ o.title }}</label>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
  import { defineEmits, defineProps, ref, toRefs, Ref, watch, computed, PropType } from 'vue'

  interface OptionItem {
    id: string
    title: string
  }
  const emit = defineEmits(['change', 'update:modelValue', 'update:booleanValue', 'update:valid', 'enterkey'])
  const props = defineProps({
    modelValue: {
      type: String,
      default: '',
    },
    booleanValue: {
      // use this model for 'binaryChoice'
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: 'text', // 'binaryChoice', 'singleChoice', otherwise same as HTML Input types. e.g. 'text', 'tel', 'email', 'number'..
    },
    required: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array as PropType<OptionItem[]>,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    // Use for scaling the input size for text input
    customSize: {
      type: String,
      default: '',
    },
    border: {
      type: Boolean,
      default: false,
    },
    underline: {
      type: Boolean,
      default: true,
    },
  })
  const { mode, modelValue, booleanValue, id } = toRefs(props)
  const selectedValue: Ref<string | boolean> = mode.value === 'binaryChoice' ? ref(booleanValue.value) : ref(String(modelValue.value))
  const selectedMultiChoice: Ref<string[]> = ref([])
  const elementId = id.value ? id.value : 'ai-id-' + Math.floor(Math.random() * 10000000)
  const emailRegex = new RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
  const telRegex = new RegExp(/^\+?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{2,6}$/im)
  const noWhitespacesRegex = new RegExp(/^(?!.*\s)/)

  watch(
    () => props.modelValue,
    (newValue) => {
      selectedValue.value = String(newValue)
      emit('update:valid', isValid.value)
    },
  )

  watch(
    () => props.booleanValue,
    (newValue) => {
      selectedValue.value = !!newValue
      emit('update:valid', isValid.value)
    },
  )

  const enterKey = () => {
    emit('enterkey')
  }

  const isValid = computed(() => {
    let valid = false
    const v = selectedValue.value
    if (!v && props.required) return false
    switch (mode.value) {
      case 'tel':
        valid = telRegex.test(v as string)
        break
      case 'password':
        valid = noWhitespacesRegex.test(v as string)
        break
      case 'email':
        valid = emailRegex.test(v as string)
        break
      case 'binaryChoice':
        valid = selectedValue.value === true
        break
      default:
        valid = true
    }
    return valid
  })

  const valueInput = ($event: Event): void => {
    // const ie = $event.target as HTMLInputElement
    $event.stopImmediatePropagation()
    emit('update:modelValue', selectedValue.value) // If using v-model on this element, this is the updated value
    emit('update:booleanValue', selectedValue.value) // If using v-model on this element, this is the updated value
    emit('update:valid', isValid.value) // Vue3 supports multiple v-model using naming - indicates the current entry is valid
    emit('change', selectedValue.value) // Also possible to listen for this change event if not using v-model
  }
</script>

<style lang="postcss" scoped>
  .valid-entry {
    @apply bg-green-400;
  }
  .invalid-entry {
    @apply bg-red-400;
  }
  label {
    @apply pointer-events-none;
  }
  input:-webkit-autofill {
    background-color: white !important;
  }
</style>
