<template>
  <div class="flex flex-col">
    <div
      class="burger-size fixed top-3 right-3 z-50 cursor-pointer inline-block rounded-full"
      :class="{
        change: openMenu,
        'burger-active': openMenu,
        'burger-inactive': !openMenu,
      }"
      @click="toggleMenu"
    >
      <div class="burger-size flex flex-col items-center justify-center">
        <div class="bar" :class="barColor"></div>
        <div class="bar my-1" :class="barColor"></div>
        <div class="bar" :class="barColor"></div>
      </div>
    </div>

    <div
      class="h-screen max-w-sm fixed top-0 right-0 z-10 transform transition-all"
      :class="{
        'translate-x-full': !openMenu,
        'translate-x-0': openMenu,
      }"
      style="background-color: #febfba"
    >
      <h1 class="flex flex-wrap pt-20 pl-10">{{ title }}</h1>
      <ul class="flex flex-wrap pl-10">
        <li v-for="(button, buttonIndex) in list" :key="buttonIndex" class="w-full my-2" @click="buttonClicked(button.event)">
          <button>
            {{ button.title }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, computed, PropType } from 'vue'
  import { HamburgerMenuEvent } from '@/constants'

  interface ListItem {
    title: string
    event: HamburgerMenuEvent
  }

  export default defineComponent({
    name: 'HamburgerMenu',
    props: {
      list: {
        type: Object as PropType<ListItem[]>,
        required: true,
      },
      title: {
        type: String,
        default: '',
      },
    },
    emits: ['button-clicked'],
    setup(props, context) {
      const openMenu = ref(false)
      const barColor = computed(() => (openMenu.value ? 'bg-white' : 'bg-black'))

      const buttonClicked = (event: string): void => {
        context.emit('button-clicked', event) // Also possible to listen for this change event if not using v-model
        openMenu.value = false
      }

      const toggleMenu = () => {
        openMenu.value = !openMenu.value
      }

      return {
        buttonClicked,
        toggleMenu,
        openMenu,
        barColor,
      }
    },
  })
</script>

<style scoped lang="postcss">
  .bar {
    width: 28px;
    height: 3px;
    transition: 0.4s;
  }

  .change .bar:nth-child(1) {
    -webkit-transform: translateY(7.5px) rotate(45deg);
    transform: translateY(7.5px) rotate(45deg);
  }

  .change .bar:nth-child(2) {
    opacity: 0;
  }

  .change .bar:nth-child(3) {
    -webkit-transform: translateY(-7px) rotate(-45deg);

    transform: translateY(-7px) rotate(-45deg);
  }

  .burger-size {
    width: 40px;
    height: 40px;
  }

  .burger-active {
    background: #0a3343;
  }

  .burger-inactive {
    background: #ffffff;
  }
</style>
