<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="flex flex-col h-full">
    <div class="flex flex-wrap justify-between sm:justify-end sm:absolute sm:top-0 sm:right-0">
      <p class="w-full sm:w-auto sm:mr-16">User type: {{ user.profile.kind }}</p>
      <p class="sm:mr-4">Language / Språk:</p>
      <select v-model="selectedLanguage">
        <option v-for="(langValue, langKey) of LanguageNames" :key="`Lang${langKey}`" :value="langKey">
          {{ langValue }}
        </option>
      </select>
    </div>
    <div v-if="consentForm" class="flex flex-col px-4 flex-grow justify-end">
      <div class="sm:h-96 flex-shrink overflow-y-scroll bg-white break-normal border" v-html="consentForm.content"></div>
      <div v-if="!done" class="flex flex-col justify-self-end justify-center sm:h-auto">
        <div v-for="(consent, index) in consents" :key="'consent' + index" class="flex flex-row my-4">
          <AnswerInput v-model:boolean-value="consent.given" class="flex justify-center px-2" mode="binaryChoice" :border="false"></AnswerInput>
          <p class="font-serious" v-html="consent.description"></p>
        </div>
        <SlButton
          v-if="consents.some((c) => !c.hasConsented)"
          class="my-2 self-center capitalize"
          background-class="bg-green-highlight"
          @click="confirmConsent"
          >{{ t('consentSave') }}
        </SlButton>
      </div>
      <SlButton class="my-2 self-center capitalize" background-class="bg-red-500" @click="finishConsent">{{ t('consentDone') }} </SlButton>
    </div>
    <p v-else>No consents found</p>
    <!-- TODO: translate -->
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, onMounted, ref, Ref, watch } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { USER_ROLE, LanguageNames, BrowserLanguageCodes } from '../constants'
  import { User, ConsentRecord, UserConsent } from '@/types/main'

  import { useAppStore } from '../store/useAppStore'
  import { useUserStore } from '../store/useUserStore'
  import { useCMSStore } from '../store/useCMSStore'

  import router from '../router'

  import SlButton from '@/components/base/SlButton.vue'
  import AnswerInput from '@/components/base/AnswerInput.vue'

  const messages = {
    'nb-NO': {
      'thank you for registering': 'Takk for du deltar i Språksterk 1-6. ',
      'not given all consents': 'For å delta i dette prosjektet må du samtykke til begge spørsmålene',
      consentDone: 'Til forsiden',
      consentSave: 'Lagre ditt svar',
      consentGiven: 'Du allerede ga samtykke, dette kan ikke annulleres. Ditt svar er:',
    },
    en: {
      'thank you for registering': 'Thank you for participating in The Oslo Early Education Study (OEES).',
      'not given all consents': ', "You have to consent to both questions to participate in the project.',
      consentDone: 'Exit',
      consentSave: 'Save your consent',
      consentGiven: "You already gave your consent, this action can't be reverted. Your answer is:",
    },
  }
  // This component completes setup of the app after login
  export default defineComponent({
    name: 'ConsentForm',
    components: {
      SlButton,
      AnswerInput,
    },
    setup() {
      const { t } = useI18n({ messages })
      const { getters: appGetters, actions: appActions } = useAppStore()
      const { getters: userGetters, actions: userActions } = useUserStore()
      const { getters: cmsGetters, actions: cmsActions } = useCMSStore()
      const consentForm = cmsGetters.consentForm
      const consents: Ref<ConsentRecord[]> = ref([])
      const user = new User(userGetters.myUser.value)
      const selectedLanguage = ref(user.profile.language)
      const done = ref(user.consented())

      const getData = () => {
        cmsActions.getConsentForm().then(() => {
          cmsActions.getTexts().then(() => {
            consents.value = []
            cmsGetters.consentForm.value?.consents.forEach((c: ConsentRecord) => {
              const userConsent = user.consents.find((uc: UserConsent) => uc.ref === c.ref)
              console.log(user.consents)
              consents.value.push({
                description: c.description,
                ref: c.ref,
                given: userConsent?.consented || true,
                hasConsented: userConsent?.date instanceof Date || false, // default to false if no date provided
              })
            })
          })
        })
      }

      // Languages are decided by a user, so we only need translations after a user has logged in
      onMounted(() => {
        getData()
      })

      watch(selectedLanguage, (value) => {
        appActions.setLanguageCode(value as BrowserLanguageCodes)
        getData()
      })

      const hasMonitorRole = computed(() => userActions.hasMinimumRole(userGetters.myUser.value, USER_ROLE.monitor))

      const confirmConsent = () => {
        if (consentForm.value) {
          user.consents = consents.value.map((c) => {
            return {
              method: 'app',
              consented: c.given,
              ref: c.ref,
              date: new Date(),
            }
          })

          // set password to '' to not update it
          user.profile.password = ''
          userActions.updateUser(user).then(() => {
            done.value = true
          })
        }
      }

      const finishConsent = () => {
        setTimeout(() => {
          appActions.setFeedbackMessage('')
        }, 4000)
        if (consents.value.some((c) => !c.given)) {
          appActions.setFeedbackMessage(t('thank you for registering') + t('not given all consents'))
          appActions.logout(false).then(() => router.push('/'))
        } else {
          appActions.setFeedbackMessage(t('thank you for registering'))
          router.push('/dashboard')
        }
      }

      return {
        t,
        // Computed
        status: appGetters.status,
        user,
        hasMonitorRole,
        LanguageNames,
        selectedLanguage,

        // Texts
        consentForm,
        consents,

        // Methods
        monitor: () => router.push('/monitor'),
        confirmConsent,
        done,
        finishConsent,
      }
    },
  })
</script>

<style scoped></style>
