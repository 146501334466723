import { computed, ref, Ref, ComputedRef } from 'vue'
import { CordovaPathName, USER_ROLE } from '../constants'
import { apiRequest } from '../api/apiRequest'
import { hasMinimumRole } from '../utilities'
import { User, Participant, UserData, LocalUser, APIRequestPayload, XHR_REQUEST_TYPE, NudgeMetadata, ActionType, CordovaData } from '../types/main'
import { useAppStore } from './useAppStore'
import { useParticipantStore } from './useParticipantStore'
import useDeviceService from '@/composition/useDevice'

const { actions: deviceActions } = useDeviceService()
const { actions: appActions } = useAppStore()
const { actions: participantActions } = useParticipantStore()

// ------------  State (internal) --------------

interface State {
  myUser: User
  selectedUser: User
  allUsers: User[]
  cordovaPath: string[]
  allNudges: Array<NudgeMetadata>
}

const state: Ref<State> = ref({
  myUser: new User(), // The actual logged in User. Initialised after successful login
  selectedUser: new User(), // This user is the model for making changes to a User
  allUsers: [], // All users in the system, for admins
  cordovaPath: [],
  allNudges: [],
})
// ------------  Internal functions ------------

async function fetchMyUser(): Promise<UserData> {
  const payload: APIRequestPayload = {
    method: XHR_REQUEST_TYPE.GET,
    credentials: true,
    route: '/api/user',
  }
  return apiRequest<UserData>(payload)
}

async function fetchAllUsers(): Promise<UserData[]> {
  const payload: APIRequestPayload = {
    method: XHR_REQUEST_TYPE.GET,
    credentials: true,
    route: '/api/users',
  }
  return apiRequest<UserData[]>(payload)
}

async function sendUpdateUser(user: User): Promise<void> {
  const payload: APIRequestPayload = {
    method: XHR_REQUEST_TYPE.PUT,
    credentials: true,
    route: `/api/user/${user._id}`,
    body: user,
  }
  return apiRequest(payload)
}

async function sendEnrollUser(user: User): Promise<void> {
  const payload: APIRequestPayload = {
    method: XHR_REQUEST_TYPE.POST,
    credentials: true,
    route: '/api/enroll',
    body: user,
  }
  return apiRequest(payload)
}

async function checkPhoneNumber(mobileNumber: string): Promise<{ phoneExists: boolean }> {
  const payload: APIRequestPayload = {
    method: XHR_REQUEST_TYPE.POST,
    credentials: true,
    route: '/api/phoneExists',
    body: { mobileNumber },
  }
  return apiRequest(payload)
}

async function fetchNudges(): Promise<Array<NudgeMetadata>> {
  const payload: APIRequestPayload = {
    method: XHR_REQUEST_TYPE.GET,
    credentials: true,
    route: '/api/nudgemetadata',
  }
  return apiRequest(payload)
}

async function respondNudge(nudge: NudgeMetadata): Promise<NudgeMetadata> {
  const payload: APIRequestPayload = {
    method: XHR_REQUEST_TYPE.PUT,
    credentials: true,
    route: `/api/nudgeResponded/${nudge._id}`,
    body: nudge,
    query: nudge.type ? { type: nudge.type } : undefined,
  }

  console.log(payload)
  return apiRequest(payload)
}

async function respondAudioNudge(nudge: NudgeMetadata): Promise<NudgeMetadata | undefined> {
  if (nudge.audioData) {
    // Convert to FormData
    const formdata = new FormData()
    formdata.append('audioFile', nudge.audioData)
    formdata.append('nudgemetadata', JSON.stringify(nudge))

    const payload: APIRequestPayload = {
      method: XHR_REQUEST_TYPE.PUT,
      credentials: true,
      route: `/api/nudgeAudioResponded/${nudge._id}`,
      body: formdata,
      query: { type: ActionType.RECORD },
      contentType: 'multipart/form-data',
    }

    console.log(payload)
    return apiRequest(payload)
  } else Promise.reject()
}

// ------------  Getters --------------

// Once a reactive getter has been gotten by a component
// we cannot overwrite its instance here in the store - but we can write to its children reactively
// Complex objects provided by a getter here should be represented by a Class and also have an update() function
interface Getters {
  myUser: ComputedRef<User>
  selectedUser: ComputedRef<User>
  allUsers: ComputedRef<User[]>
  allNudges: ComputedRef<Array<NudgeMetadata>>
}
const getters = {
  get myUser(): ComputedRef<User> {
    return computed(() => state.value.myUser) // This is the current logged in user and should not change during app usage
  },
  get selectedUser(): ComputedRef<User> {
    return computed(() => state.value.selectedUser) // This is the 'currently selected' user and can change, must change by calling User.update()
  },
  get allUsers(): ComputedRef<User[]> {
    return computed(() => state.value.allUsers) // Unlikely to change during app usage, but ok as long as the array itself is not overwitten
  },
  get allNudges(): ComputedRef<Array<NudgeMetadata>> {
    return computed(() => state.value.allNudges)
  },
}

// ------------  Actions --------------
interface Actions {
  hasMinimumRole: (user: User, role: USER_ROLE) => boolean
  selectUser: (user: User) => void
  setParticipants: (participants: Participant[]) => void

  // Server
  getMyUser: () => Promise<void>
  getAllUsers: () => Promise<void>
  updateUser: (user: User) => Promise<void>
  enrollUser: (user: User) => Promise<void>
  phoneExists: (mobileNumber: string) => Promise<{ phoneExists: boolean }>
  getMyNudges: () => Promise<void>
  completeNudge: (nudge: NudgeMetadata) => Promise<void>

  // Disk
  setCordovaPath: (userID: string) => void
  loadUser: () => Promise<void>
  saveUser: () => Promise<void>
}
const actions = {
  // Retrieve from server the user details (called after login when online & not mobile)
  getMyUser: async function (): Promise<void> {
    appActions.setLoading(true)
    const response: UserData = await fetchMyUser()
    state.value.myUser.update(response)
    state.value.selectedUser.update(state.value.myUser)
    state.value.cordovaPath = [CordovaPathName.users, state.value.myUser._id]
    participantActions.setParticipants(state.value.myUser.participants)
    const newLocalUser: LocalUser = {
      _id: state.value.myUser._id,
      name: state.value.myUser.profile.fullName,
      lastLogin: new Date(),
      jwt: localStorage.getItem('jwt') || '',
      pin: '',
      selected: true,
    }
    appActions.setCurrentLocalUser(newLocalUser)
    appActions.setLoading(false)
    return Promise.resolve()
  },

  // Retrieve from server a listing of all users
  getAllUsers: async function (): Promise<void> {
    appActions.setLoading(true)
    const response: UserData[] = await fetchAllUsers()
    const users = response.map((u: UserData) => new User(u))
    state.value.allUsers = users
    appActions.setLoading(false)
    return Promise.resolve()
  },

  // Update a given user at server, and locally if it exists in allUsers
  updateUser: async function (user: User): Promise<void> {
    return sendUpdateUser(user).then(() => {
      // Also update the user in local list
      let modifiedUser = state.value.allUsers.find((u) => u._id === user._id)
      if (!modifiedUser && user._id === state.value.myUser._id) {
        modifiedUser = state.value.myUser
      }
      if (modifiedUser) modifiedUser.update(user)
    })
  },

  // Create a new user
  enrollUser: async function (user: User): Promise<void> {
    return sendEnrollUser(user)
  },

  // Check phone number
  phoneExists: async function (mobileNumber: string): Promise<{ phoneExists: boolean }> {
    return checkPhoneNumber(mobileNumber)
  },

  // Check that the selected user has at least the role requested
  // Direct reference to (utility function)
  hasMinimumRole,

  setParticipants: function (participants: Participant[]) {
    state.value.myUser.participants = participants
  },

  selectUser: function (user: User) {
    const u = state.value.allUsers.find((us) => us._id === user._id)
    if (u) {
      state.value.selectedUser = u
    }
  },
  setCordovaPath: function (userID: string): void {
    state.value.cordovaPath = [CordovaPathName.users, userID]
  },
  loadUser: function (): Promise<void> {
    const cd: CordovaData = new CordovaData({
      fileName: 'user.json',
      readFile: true,
      asText: true,
      asJSON: true,
      path: state.value.cordovaPath,
    })
    return new Promise((resolve) => {
      deviceActions.loadFromStorage<UserData>(cd).then((data) => {
        if (data) {
          state.value.myUser.update(data)
          state.value.selectedUser.update(state.value.myUser)
          participantActions.setParticipants(state.value.myUser.participants)
          resolve()
        }
      })
    })
  },
  saveUser: function (): Promise<void> {
    const cd: CordovaData = new CordovaData({
      fileName: 'user.json',
      data: state.value.myUser.asPOJO(),
      asText: true,
      asJSON: true,
      path: state.value.cordovaPath,
    })
    return deviceActions.saveToStorage(cd)
  },

  getMyNudges: async function (): Promise<void> {
    appActions.setLoading(true)
    const response: Array<NudgeMetadata> = await fetchNudges()
    state.value.allNudges = response
    appActions.setLoading(false)
    return Promise.resolve()
  },

  completeNudge: async function (nudge: NudgeMetadata): Promise<void> {
    let response: NudgeMetadata | undefined
    if (nudge.type == ActionType.RECORD) response = await respondAudioNudge(nudge)
    else response = await respondNudge(nudge)

    if (response && response._id) {
      const nudgeIndex = state.value.allNudges.findIndex((n) => response && n._id == response._id)
      if (nudgeIndex > -1) state.value.allNudges.splice(nudgeIndex, 1, response)
    } else {
      console.log("Didn't find Nudgemetadata")
    }
  },
}

interface ServiceInterface {
  actions: Actions
  getters: Getters
}
// This defines the interface used externally
export function useUserStore(): ServiceInterface {
  return {
    getters,
    actions,
  }
}

export type UserStoreType = ReturnType<typeof useUserStore>
// export const UserKey: InjectionKey<UseUser> = Symbol('UseUser')
