<template>
  <div class="pl-2 my-2">
    <div class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
      {{ title }}
    </div>

    <input :id="isParent ? 'p-datepicker' : participantIndex + '-c-datepicker'" class="p-4" type="text" autocomplete="off" />
  </div>
</template>

<script lang="ts">
  import { defineComponent, onMounted, ref, Ref } from 'vue'
  import moment from 'moment'
  import { useI18n } from 'vue-i18n'
  import Pikaday from 'pikaday'

  const messages = {
    no_NB: {
      birthday: 'Fødselsdato',
    },
    en: { birthday: 'Birth date' },
  }

  export default defineComponent({
    name: 'DatePicker',
    props: {
      title: {
        type: String,
        default: '',
      },
      mode: {
        type: String,
        default: 'parent',
      },
      participantIndex: {
        type: Number,
        default: 0,
      },
    },
    emits: ['update'],
    setup(props, context) {
      const { t } = useI18n({ messages })

      const isParent = props.mode == 'parent'
      const defaultDate = isParent ? '01/01/1990' : '01/01/2017'
      const startingDate = isParent ? '01/01/1950' : '01/01/2017'
      const endDate = isParent ? '01/01/2020' : '01/04/2020'
      const dateValue = moment(startingDate, 'DD/MM/YYYY', true)
      const daysInMonth = ref(dateValue.daysInMonth())
      let pickADay: Pikaday

      const month: Ref<null | number> = ref(null)
      const date: Ref<null | number> = ref(null)
      const year: Ref<null | string> = ref(null)
      const months = ref(12)

      const startingYear = isParent ? 1950 : 2017
      const numberofYears = isParent ? 60 : 4

      const updateElements = () => {
        // month.value gives us the <select>
        if (month.value) month.value = dateValue.month()
        if (date.value) date.value = dateValue.date()
        if (year.value) year.value = dateValue.format('YYYY')
      }

      const emitUpdate = (date: moment.Moment) => {
        context.emit('update', {
          date: date.toString(),
          isParent,
          participantIndex: props.participantIndex,
        })
      }

      onMounted(() => {
        const callback = () => {
          if (pickADay) {
            const m = pickADay.getMoment()
            if (m) emitUpdate(m)
          }
        }
        pickADay = new Pikaday({
          field: document.getElementById(isParent ? 'p-datepicker' : props.participantIndex + '-c-datepicker'),
          format: 'DD/MM/YYYY',
          defaultDate: moment(defaultDate, 'DD/MM/YYYY', true).toDate(),
          minDate: moment(startingDate, 'DD/MM/YYYY', true).toDate(),
          maxDate: moment(endDate, 'DD/MM/YYYY', true).toDate(),
          onSelect: callback,
        })
      })

      return {
        months,
        numberofYears,
        startingYear,
        daysInMonth,
        isParent,
        updateElements,

        month,
        date,
        year,

        t,
      }
    },
  })
</script>

<style scoped>
  @import '../../../node_modules/pikaday/css/pikaday.css';

  .custom-select {
    background-color: white;
    float: left;
    margin-right: 10px;
    position: relative;
  }

  .custom-select select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none; /* remove default styling */
    background-color: inherit;
    border: none;
    color: #333;
    display: block;
    font-size: 16px;
    height: 32px;
    padding: 5px 30px 5px 10px;
    margin: 0;
    outline: none;
  }

  /* drop arrow */
  .custom-select:after {
    content: '\25bc';
    color: #aaa;
    font-size: 12px;
    position: absolute;
    right: 8px;
    top: 10px;
  }
</style>
