import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import Landing from '../views/landing/Landing.vue'
import AfterLogin from '../views/landing/AfterLogin.vue'
import Dashboard from '../views/DashboardView.vue'
import ConsentForm from '../views/ConsentForm.vue'
import Enrollment from '../views/Enrollment.vue'

import { useTableStore } from '../store/useTableStore'
const { actions: tableActions } = useTableStore()

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:admin?',
    name: 'Landing',
    component: Landing,
  },
  { path: '/index.html', redirect: '/' },
  {
    path: '/login',
    name: 'Login',
    component: AfterLogin,
  },
  {
    path: '/consent',
    name: 'Consent',
    component: ConsentForm,
  },
  {
    path: '/enroll',
    name: 'Enrollment',
    component: Enrollment,
    beforeEnter(to, from, next) {
      tableActions
        .getAllWards()
        .then(() => next())
        .catch((error) => {
          console.log(error)
          router.push('/')
        })
    },
  },
  {
    path: '/monitor',
    name: 'Monitor',
    component: () => import('../views/admin/Monitor.vue'),
    children: [
      {
        // Ward Table will be rendered inside User's <router-view>
        // when /monitor/wards is matched
        path: 'wards',
        name: 'Wards  Table',
        component: () => import('../views/admin/MonitorWards.vue'),
      },
      {
        // User Table will be rendered inside User's <router-view>
        // when /monitor/users is matched
        path: 'users',
        name: 'Users Table',
        component: () => import('../views/admin/MonitorUsers.vue'),
      },
      {
        // User Table will be rendered inside User's <router-view>
        // when /monitor/users is matched
        path: 'everyone',
        name: 'Everyone Table',
        component: () => import('../views/admin/MonitorEveryone.vue'),
      },
      {
        // Participant Table will be rendered inside User's <router-view>
        // when /monitor/participants is matched
        path: 'participants',
        name: 'Participants  Table',
        component: () => import('../views/admin/MonitorParticipants.vue'),
      },
      {
        // Participant Table will be rendered inside User's <router-view>
        // when /monitor/participants is matched
        path: 'nudges',
        name: 'Nudges  Table',
        component: () => import('../views/admin/MonitorNudges.vue'),
      },
      {
        // Participant Table will be rendered inside User's <router-view>
        // when /monitor/participants is matched
        path: 'nudgemetadatas',
        name: 'Nudgemetadatas  Table',
        component: () => import('../views/admin/MonitorNudgeMetadatas.vue'),
      },
      {
        // Participant Table will be rendered inside User's <router-view>
        // when /monitor/participants is matched
        path: 'nudgemetadata_paging',
        name: 'NudgemetadataPaging Table',
        component: () => import('../views/admin/MonitorNudgeMetadatasPaging.vue'),
      },
      {
        // Management Table will be rendered inside User's <router-view>
        // when /monitor/management is matched
        path: 'management',
        name: 'Management  Table',
        component: () => import('../views/user/MonitorEnrolledUsers.vue'),
      },
    ],
  },
  {
    path: '/dashboard/:reloaded?',
    name: 'Dashboard',
    component: Dashboard,
    children: [
      {
        // Ward Table will be rendered inside User's <router-view>
        // when /monitor/wards is matched
        path: 'nudges',
        name: 'Nudges  view',
        component: () => import('../views/dashboard/DashboardNudgeView.vue'),
      },
    ],
  },
]

const routerType = createWebHistory(import.meta.env.BASE_URL)
const router = createRouter({
  history: routerType,
  routes,
})

router.beforeEach((to, from, next) => {
  const isSample = to.path.includes('/sample/')
  if (!(from.name || to.redirectedFrom?.name) && to.name !== 'Login' && to.name !== 'Landing' && to.name !== 'Admin Login' && !isSample) {
    next('/')
  } else next()
})

export default router
