<template>
  <div class="flex flex-col bg-gray-200 min-h-full">
    <h2 class="text-xl m-4">{{ t('enrollment') }}</h2>
    <div class="flex flex-col justify-between mx-4">
      <div class="flex flex-row flex-wrap justify-between">
        <SlButton id="button-accept" text-class="text-black" background-class="bg-white" @click="newUser">{{ t('newUser') }} </SlButton>
        <SlButton id="button-accept" class="ml-4 capitalize" background-class="bg-red-500" @click="finishEnrollment"
          >{{ t('enrollmentDone') }}
        </SlButton>
      </div>
    </div>
    <div v-if="enrollingUser" class="m-4 flex flex-col">
      <p>{{ t('UserDetails') }}</p>
      <div class="m-4 flex flex-col relative">
        <SelectionBox
          id="select-kind"
          v-model="currentUserKind"
          :label="t('userKind')"
          :options="kindOptionList"
          :reset-on-choose="false"
        ></SelectionBox>
        <template v-if="!dontNeedWard">
          <SelectionBox
            v-if="canChooseWard"
            id="select-kind"
            v-model="currentUserWard"
            :label="t('ward')"
            :options="wardOptionList"
            :reset-on-choose="false"
          ></SelectionBox>
          <p v-else class="m-2">Ward: {{ wardOptionList[0].itemName }}</p>
        </template>
        <template v-else-if="currentUserKind.item === USER_KIND.styrer">
          <div class="flex flex-col max-w-sm m-2">
            <p class="uppercase text-gray-700 text-xs font-bold pb-2">
              {{ t('Kindergarten') }}
            </p>

            <select
              v-model="currentUserKindergartens"
              class="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              style="height: 9em"
              multiple
            >
              <option value="" disabled>{{ t('choose one or more') }}</option>
              <option v-for="(o, i) in kindergartenOptionList" :key="i" :value="o.item">
                {{ o.itemName }}
              </option>
            </select>
          </div>
        </template>
        <AnswerInput
          v-if="enrollingUser"
          v-model="enrollingUser.profile.fullName"
          v-model:valid="validators.fullName"
          class="m-2"
          mode="text"
          :border="false"
          :label="t('fullname')"
          :required="true"
          @change="saved = false"
        ></AnswerInput>

        <Datepicker :title="t('birthday')" @update="dateChanged"></Datepicker>

        <div class="relative">
          <div
            v-if="enrollingUser && enrollingUser.profile.mobileNumber"
            v-show="phoneNumberExists"
            class="w-full absolute mt-2 flex justify-center uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
          >
            {{ !validators.mobil && phoneNumberisValid ? t('Phone exists') : t('Phonestart') }}
          </div>
          <AnswerInput
            v-if="enrollingUser"
            v-model="enrollingUser.profile.mobileNumber"
            v-model:valid="validators.mobil"
            class="m-2"
            mode="tel"
            :border="false"
            :label="t('mobilNumber')"
            :required="true"
            :class="phoneNumberExists ? 'invalid-value' : ''"
            @change="saved = false"
          ></AnswerInput>
        </div>
        <AnswerInput
          v-if="enrollingUser"
          v-model="enrollingUser.profile.email"
          v-model:valid="validators.email"
          class="m-2"
          mode="email"
          :border="false"
          :label="t('email')"
          :required="true"
          placeholder="user@example.com"
          @change="saved = false"
        ></AnswerInput>
        <SelectionBox
          id="select-kind"
          v-model="currentLanguage"
          class="hidden"
          :label="t('language')"
          :options="languagesList"
          :reset-on-choose="false"
        ></SelectionBox>

        <div class="relative flex flex-col max-w-sm m-2" :class="currentUserKind.item == USER_KIND.foresatt ? '' : 'hidden'">
          <p class="uppercase text-gray-700 text-xs font-bold pb-2">
            {{ t('nativeLanguage') }}
          </p>

          <!--    <select
            v-model="nativeLanguage"
            class="px-3 py-5"
            :class="nativeLanguage ? '' : 'bg-red-500'"
          >
            <option v-for="(language, index) in nativeLanguages" :key="index">
              {{ language }}
            </option>
          </select> -->
          <input
            v-model="nativeLanguage"
            class="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            :class="nativeLanguage ? '' : 'bg-red-500'"
            list="nativelist"
            autocomplete="off"
            type="text"
          />
          <datalist id="nativelist">
            <option v-for="(language, index) in nativeLanguages" :key="index">
              {{ language }}
            </option>
          </datalist>
        </div>
        <!-- ACTIVATEPASSWORD: Remove comment -->
        <!--  <AnswerInput
          class="m-2"
          mode="password"
          :border="false"
          :label="t('password')"
          :required="true"
          v-model="enrollingUser.profile.password"
          v-model:valid="validators.password"
          @change="saved = false"
        ></AnswerInput> -->
      </div>
      <div v-if="currentUserKind.item === USER_KIND.foresatt && participants.length > 0" class="flex flex-col">
        <p>{{ t('ParticipantDetails') }}</p>
        <div v-for="(p, i) in participants" :key="p.lid" class="m-4 flex flex-col">
          <div class="flex flex-row flex-wrap items-center">
            <!--<p class="text-xs">#{{ i }}:</p>-->
            <AnswerInput
              v-model="p.profile.name"
              v-model:valid="participantValidators[i].name"
              class="m-2"
              mode="text"
              :border="false"
              :label="t('childname')"
              :required="true"
              @change="saved = false"
            ></AnswerInput>
            <Datepicker :title="t('childdate')" mode="child" :participant-index="i" @update="dateChanged"></Datepicker>
            <AnswerInput
              v-model="p.profile.gender"
              v-model:valid="participantValidators[i].gender"
              class="m-2"
              mode="singleChoice"
              :options="[
                { title: t('boy'), id: 'boy' },
                { title: t('girl'), id: 'girl' },
              ]"
              :border="false"
              :label="t('gender')"
              :required="true"
              @change="saved = false"
            ></AnswerInput>
          </div>
          <SlButton v-if="i > 0" id="button-accept" class="my-2 self-end" background-class="bg-red-400" @click="removeParticipant(p)"
            >{{ t('removeParticipant') }}
          </SlButton>
        </div>
      </div>
      <SlButton
        v-if="currentUserKind.item === USER_KIND.foresatt"
        id="button-accept"
        class="my-2 self-start"
        text-class="text-black"
        background-class="bg-white"
        @click="newParticipant"
        >{{ t('newParticipant') }}
      </SlButton>
    </div>
    <div class="w-full flex items-center justify-center py-8">
      <p v-if="enrollingUser" :class="{ 'text-red-600': !saved }">{{ !saved ? t('notSaved') : t('saved') }}<br /></p>
      <SlButton
        v-if="!saved"
        id="button-accept"
        class="ml-4 self-center capitalize"
        :background-class="saveReady ? 'bg-green-highlight' : 'bg-gray-200'"
        @click="confirmCreateUser"
        >{{ t('save') }}
      </SlButton>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, ComputedRef, ref, Ref, watch } from 'vue'
  import { useI18n } from 'vue-i18n'
  import router from '../router'
  import { USER_ROLE, LanguageNames, BrowserLanguageCodes, USER_KIND, nativeLanguages } from '../constants'
  import { useAppStore } from '../store/useAppStore'
  import { useUserStore } from '../store/useUserStore'
  import { useTableStore } from '../store/useTableStore'
  import { User, Participant, Ward } from '@/types/main'
  import SlButton from '@/components/base/SlButton.vue'
  import AnswerInput from '@/components/base/AnswerInput.vue'
  import SelectionBox from '@/components/base/SelectionBox.vue'
  import Datepicker from '@/components/base/Datepicker.vue'

  const { getters: tableGetters } = useTableStore()
  const { getters: userGetters, actions: userActions } = useUserStore()
  const { getters: appGetters } = useAppStore()

  const messages = {
    nb_NO: {
      enrollmentDone: 'Til forsiden',
      'choose one or more': 'Velg en eller flere barnehager..',
      Kindergarten: 'Barnehage',
      ParticipantDetails: 'Informasjon om deltagende barn',
      UserDetails: 'Informasjon om personen som skal samtykke',
      newUser: 'Ny bruker',
      newParticipant: 'Legg til barn',
      removeParticipant: 'Slett barn',
      mobilNumber: 'Mobilnummer',
      password: 'Velg passord',
      fullname: 'Fullt navn',
      email: 'E-postadresse',
      language: 'Foretrukket språk',
      nativeLanguage: 'Annet morsmål som brukes hjemme (dersom kun norsk, velg norsk)',
      userKind: 'Rolle',
      ward: 'Avdeling',
      notSaved: 'Ikke lagret enda!',
      missingData: 'Det mangler data',
      saved: 'Lagret',
      enrollment: 'Registrering',
      noWardFound: 'Ingen avdeling!',
      Phonestart: 'Mobilnummer må være 8 siffer hvor første siffer er 4 eller 9',
      'Phone exists': 'Dette mobilnummeret er registrert allerede',
      birthday: 'Fødselsdato',
      childname: 'Barnets fulle navn',
      childdate: 'Barnets fødselsdato',
      gender: 'Barnets kjønn',
      boy: 'Gutt',
      girl: 'Jente',
    },
    en: {
      enrollmentDone: 'To Dashboard',
      'choose one or more': 'Choose one or several kindergartens..',
      Kindergarten: 'Kindergarten',
      ParticipantDetails: 'Information about participating child(ren)',
      UserDetails: 'Information about the person giving consent',
      newUser: 'New user',
      newParticipant: 'Add child',
      removeParticipant: 'Remove child',
      mobilNumber: 'Mobile number',
      password: 'Choose a password',
      fullname: 'Full name',
      email: 'E-mail address',
      language: 'Language',
      nativeLanguage: 'Another language used at home (if only Norwegian, choose Norwegian)',
      userKind: 'Role',
      ward: 'Ward',
      notSaved: 'Not yet saved!',
      missingData: 'Data is missing',
      saved: 'Saved',
      enrollment: 'Enrollment',
      noWardFound: 'No ward found!',
      Phonestart: 'Phonenumber must be 8 digits where the first digit is either 4 or 9',
      'Phone exists': 'This mobile number is already registered',
      birthday: 'Birth date',
      childname: 'Fullname of child',
      childdate: "Child's birth of date",
      gender: "Child's gender",
      boy: 'Boy',
      girl: 'Girl',
    },
  }

  interface KindOptionListItem {
    itemName: string
    item: USER_KIND
  }
  interface LanguageOptionListItem {
    itemName: LanguageNames
    item: BrowserLanguageCodes
  }
  interface WardOptionListItem {
    itemName: string
    item: Ward | undefined
  }
  interface KindergartenOptionListItem {
    itemName: string
    item: string
  }

  // This component completes setup of the app after login
  export default defineComponent({
    name: 'EnrollmentView',
    components: {
      SlButton,
      SelectionBox,
      AnswerInput,
      Datepicker,
    },
    setup() {
      const { t } = useI18n({ messages })

      const myUser = userGetters.myUser.value
      const myWard = computed(() =>
        tableGetters.allWards.value.find((w) => {
          return w.skRef === myUser.skRefs.ward
        }),
      )

      const canChooseWard =
        myUser.profile.kind === USER_KIND.forsker ||
        myUser.profile.kind === USER_KIND.forskningsassistent ||
        myUser.profile.kind === USER_KIND.styrer ||
        myUser.profile.role === USER_ROLE.admin

      const enrollingUser: Ref<User | undefined> = ref()
      const participants: Ref<Participant[]> = ref([])
      const saved = ref(false)
      const enrolling = ref(false)
      const validators: Ref<Record<string, boolean>> = ref({})
      const participantValidators: Ref<{ name: boolean; gender: boolean }[]> = ref([])

      const currentUserKind = ref({
        /* item: USER_KIND.foresatt,
      itemName: USER_KIND.foresatt, */
        item: '',
        itemName: '',
      })

      const languageOptionList: LanguageOptionListItem[] = Object.entries(LanguageNames).map((r) => ({
        item: r[0] as BrowserLanguageCodes,
        itemName: r[1],
      }))

      const languagesList = computed(() => {
        return currentUserKind.value.item !== USER_KIND.foresatt
          ? [
              {
                item: BrowserLanguageCodes['nb-NO'],
                itemName: LanguageNames['nb-NO'],
              },
            ]
          : languageOptionList
      })

      const nativeLanguage = ref('')

      const kindergartenOptionList: ComputedRef<KindergartenOptionListItem[]> = computed(() => {
        const dictAdded: Record<string, boolean> = {}

        const list = tableGetters.allWards.value
          .map((w) => ({
            item: w.kindergartenID,
            itemName: `${w.district} - ${w.kindergartenName}`,
          }))
          .filter((item) => {
            if (!dictAdded[item.itemName]) {
              dictAdded[item.itemName] = true
              return true
            }
            return false
          })
        return list
      })
      const currentUserKindergartens: Ref<string[]> = ref([])
      const wardOptionList: ComputedRef<WardOptionListItem[]> = computed(() => {
        let list = tableGetters.allWards.value.map((w) => ({
          item: w,
          itemName: `${w.district} - ${w.kindergartenName} - ${w.wardName}`,
        }))

        // Only 'director' & 'researcher' kinds can modify wards. Others have it fixed
        if (!canChooseWard && myWard.value) {
          list = [
            {
              item: myWard.value,
              itemName: myWard.value ? myWard.value.wardName : t('noWardFound'),
            },
          ]
        }

        // Show only wards that styrer belongs too
        if (myUser.profile.kind === USER_KIND.styrer || myUser.profile.kind === USER_KIND.forskningsassistent) {
          const kindergartens = myUser.skRefs.kindergartenIDs
          list = list.filter((w) => kindergartens.includes(w.item?.kindergartenID))
        }

        // If Styrer is chosen, show only each kindergarten
        if (currentUserKind.value.item == USER_KIND.styrer) {
          const dictAdded: Record<string, boolean> = {}
          list = list
            .map((wardItem) => {
              wardItem.itemName = `${wardItem.item.district} - ${wardItem.item.kindergartenName}`
              return wardItem
            })
            .filter((item) => {
              if (!dictAdded[item.itemName]) {
                dictAdded[item.itemName] = true
                return true
              }
              return false
            })
        }

        return list
      })
      const wolItem = wardOptionList.value[0] ? wardOptionList.value[0] : { item: undefined, itemName: '' }
      const currentUserWard = ref(wolItem)

      let kindOptionList: KindOptionListItem[] = Object.values(USER_KIND).map((r) => ({
        item: r,
        itemName: r,
      }))

      switch (myUser.profile.kind) {
        case USER_KIND.styrer:
          kindOptionList = kindOptionList.filter(
            (o) =>
              o.item !== USER_KIND.forsker &&
              o.item !== USER_KIND.styrer &&
              o.item !== USER_KIND.forskningsassistent &&
              o.item !== USER_KIND.mentor &&
              o.item !== USER_KIND.foresatt,
          )
          break
        case USER_KIND.forskningsassistent:
          kindOptionList = kindOptionList.filter(
            (o) =>
              o.item !== USER_KIND.forsker &&
              o.item !== USER_KIND.styrer &&
              o.item !== USER_KIND.forskningsassistent &&
              o.item !== USER_KIND.mentor &&
              o.item !== USER_KIND.foresatt,
          )
          break
        case USER_KIND.pedleder:
          kindOptionList = kindOptionList.filter(
            (o) =>
              o.item !== USER_KIND.forsker &&
              o.item !== USER_KIND.styrer &&
              o.item !== USER_KIND.forskningsassistent &&
              o.item !== USER_KIND.pedleder &&
              o.item !== USER_KIND.mentor &&
              o.item !== USER_KIND.foresatt,
          )
          break
        case USER_KIND.ansatt:
          kindOptionList = [
            /*   {
            item: USER_KIND.foresatt,
            itemName: USER_KIND.foresatt,
          }, */
          ]
          break
        case USER_KIND.mentor:
          kindOptionList = [
            {
              item: USER_KIND.mentor,
              itemName: USER_KIND.mentor,
            },
          ]
          break
        case USER_KIND.foresatt:
          router.push('/dashboard')
      }

      const currentLanguage = ref({
        item: BrowserLanguageCodes['nb-NO'],
        itemName: LanguageNames['nb-NO'],
      })

      // Functions depending on currentUserkind
      const dontNeedWard = computed(
        () =>
          currentUserKind.value.item === USER_KIND.forsker ||
          currentUserKind.value.item === USER_KIND.mentor ||
          currentUserKind.value.item === USER_KIND.forskningsassistent ||
          currentUserKind.value.item === USER_KIND.styrer,
      )

      const hasMonitorRole = computed(() => userActions.hasMinimumRole(userGetters.myUser.value, USER_ROLE.monitor))

      const saveReady = computed(() => {
        return !(
          (currentUserKind.value.item === USER_KIND.styrer || currentUserKind.value.item === USER_KIND.forskningsassistent) &&
          currentUserKindergartens.value.length == 0
        )
      })

      const newUser = () => {
        enrolling.value = true
        if (!enrollingUser.value) enrollingUser.value = new User()
        enrollingUser.value.clear()
        validators.value = {
          mobil: false,
          password: true, // ACTIVATEPASSWORD: Change it back to fasle when Activating password
          fullName: false,
          email: false,
        }
        participants.value = []
        participantValidators.value = []
        newParticipant()
      }
      const newParticipant = () => {
        const p = new Participant()
        if (enrollingUser.value) {
          p.skRefs.ward = enrollingUser.value.skRefs.ward
          // TODO: User will not have a scrambling key at this point!
          p.skRefs.user1 = enrollingUser.value.skRefs.self
        }
        participants.value.push(p)
        const validator = {
          name: false,
          gender: false,
        }
        participantValidators.value.push(validator)
      }

      // Remove a local participant from this editor page
      const removeParticipant = (p: Participant) => {
        const i = participants.value.findIndex((pa) => pa.lid === p.lid)
        participants.value.splice(i, 1)
        participantValidators.value.splice(i, 1)
      }

      const confirmCreateUser = () => {
        if (!saveReady.value && nativeLanguage.value) return
        if (!currentUserKind.value.item) return
        if (enrollingUser.value) {
          enrollingUser.value.profile.kind = currentUserKind.value.item as USER_KIND
          enrollingUser.value.profile.nativeLanguage = nativeLanguage.value
          enrollingUser.value.profile.language = currentLanguage.value.item

          // User's who need a ward
          if (!dontNeedWard.value && currentUserWard.value.item) {
            enrollingUser.value.skRefs.ward = currentUserWard.value.item.skRef
            enrollingUser.value.dbRefs.ward = currentUserWard.value.item._id
          }

          if (currentUserKind.value.item === USER_KIND.styrer || currentUserKind.value.item === USER_KIND.forskningsassistent) {
            enrollingUser.value.skRefs.kindergartenIDs = currentUserKindergartens.value.map((itemID: string) => itemID)
          }

          if (currentUserKind.value.item === USER_KIND.foresatt) {
            if (participants.value.length) {
              participants.value.forEach((p) => {
                if (currentUserWard.value.item) {
                  p.skRefs.ward = currentUserWard.value.item.skRef
                }
              })
            }
            enrollingUser.value.participants = participants.value
          }
          userActions
            .enrollUser(enrollingUser.value)
            .then(() => {
              saved.value = true
              newUser()
            })
            .catch((err) => {
              console.log(err.error)
              if (err.error.includes('statusCode: 403')) {
                saved.value = true
              }
            })
        }
      }

      const finishEnrollment = () => {
        const isConfirmed = confirm('Er du sikker du vil gå tilbake?')
        if (isConfirmed) router.push('/dashboard')
      }

      // Phone number methods / events
      const phoneInputEvent = (event: KeyboardEvent) => {
        // Accept only digits
        if (!(48 <= event.charCode && event.charCode <= 57)) event.preventDefault()
      }

      // Test if the phonenumber is long enough and doesn't exists already
      const phoneNumberExists = computed(() => {
        const user = enrollingUser.value
        const mobileNumber = user?.profile.mobileNumber

        if (mobileNumber) {
          const phNumber = mobileNumber.toString()
          if ((phNumber[0] == '4' || phNumber[0] == '9') && phNumber.length == 8) return !validators.value.mobil
          else return true
        } else return false
      })

      const phoneNumberisValid = computed(() => {
        const user = enrollingUser.value
        const mobileNumber = user?.profile.mobileNumber

        if (mobileNumber) {
          const phNumber = mobileNumber.toString()
          return (phNumber[0] == '4' || phNumber[0] == '9') && phNumber.length == 8
        }
        return false
      })

      const dateChanged = (data: { date: string; isParent: boolean; participantIndex: number }) => {
        if (data.isParent && enrollingUser.value) enrollingUser.value.profile.birthday = data.date
        else {
          const p = participants.value[data.participantIndex]
          p.profile.birthday = data.date
        }
      }

      /* Watchers */
      watch(
        () => enrollingUser.value?.profile.mobileNumber,
        (newValue) => {
          if (validators.value && validators.value.mobil && newValue) {
            const firstDigit = newValue.toString()[0]
            if (firstDigit == '4' || firstDigit == '9') {
              userActions
                .phoneExists(newValue)
                .then((res: { phoneExists: boolean }) => {
                  validators.value.mobil = !res.phoneExists
                })
                .catch((error) => {
                  console.log(error)
                })
            } else validators.value.mobil = false
          }
        },
      )

      // If the currentUserWard is undefined we have to wait for the wardOptionList to be completed before assigning a value
      if (!currentUserWard.value) {
        watch(
          () => wardOptionList.value,
          (list) => {
            if (list) currentUserWard.value = list[0]
          },
        )
      }

      return {
        t,
        phoneInputEvent,
        phoneNumberExists,
        phoneNumberisValid,
        nativeLanguage,
        nativeLanguages,
        enrolling,

        // Computed
        status: appGetters.status,
        hasMonitorRole,
        canChooseWard,
        LanguageNames,
        USER_KIND,
        saved,
        saveReady,
        enrollingUser,
        participants,
        currentUserKind,
        currentLanguage,
        currentUserWard,
        kindOptionList,
        languagesList,
        wardOptionList,
        kindergartenOptionList,
        currentUserKindergartens,
        dontNeedWard,
        validators,
        participantValidators,

        // Methods
        confirmCreateUser,
        newUser,
        newParticipant,
        removeParticipant,
        finishEnrollment,
        dateChanged,
        monitor: () => router.push('/monitor'),
      }
    },
  })
</script>

<style scoped></style>
