import { reactive } from 'vue'
import {
  User,
  Participant,
  ParticipantData,
  Tracking,
  TrackingData,
  APIRequestPayload,
  XHR_REQUEST_TYPE,
  XHR_CONTENT_TYPE,
  AvatarLayout,
  SpecialRequestData,
  SPECIAL_REQUEST_TYPE,
  TRACKING_TYPE,
  CordovaData,
  //XHR_CONTENT_TYPE,
} from '@/types/main'
import { apiRequest } from '../api/apiRequest'
import useDeviceService from '@/composition/useDevice'
import { ref, Ref, computed, ComputedRef } from 'vue'
import { CordovaPathName } from '@/constants'

const { actions: deviceActions, getters: deviceGetters } = useDeviceService()

// ------------  State (internal) --------------
interface State {
  participants: Map<string, Participant>
  selectedParticipant: Participant
  trackings: Map<string, Tracking>
  selectedLocation: string
  locations: string[]
  groupMode: boolean
}

const state: Ref<State> = ref({
  participants: new Map(), // The list of Participants for a User
  selectedParticipant: new Participant(), // The currently selected individual Participant. Also needed for Avatar editing etc.
  participantGroup: new Map(), // The currently selected group of Participants
  trackings: new Map(),
  locations: [],
  selectedLocation: '',
  groupMode: false,
})

// ------------  Server-side data ------------

// Create new participant at server
async function sendAddParticipanttoServer(data: ParticipantData): Promise<ParticipantData> {
  const payload: APIRequestPayload = {
    method: XHR_REQUEST_TYPE.POST,
    credentials: true,
    route: '/api/participant',
    body: data,
  }
  return apiRequest(payload)
}

// Syncronise participants with the server
async function syncParticipants(participants: Participant[]): Promise<void> {
  while (participants.length > 0) {
    const p = participants.pop()
    if (p) {
      const payload: APIRequestPayload = {
        method: XHR_REQUEST_TYPE.PUT,
        credentials: true,
        route: '/api/participant',
        body: p.asPOJO(),
      }
      let participantData
      try {
        participantData = await apiRequest<ParticipantData>(payload)
      } catch (error) {
        console.log(`Error syncing participants: ${(error as Error).toString()}`)
      }
      // After updating at server, update locally
      const localP = state.value.participants.get(p._id)
      if (localP && participantData) localP.update(participantData)
    }
  }
  return Promise.resolve()
}

// Only for updating Mastery details (no progress sync)
async function updateParticipantMastery(p: Participant): Promise<void> {
  const payload: APIRequestPayload = {
    method: XHR_REQUEST_TYPE.PUT,
    credentials: true,
    route: '/api/participant/mastery',
    body: p.asPOJO(),
  }
  let participantData
  try {
    participantData = await apiRequest<ParticipantData>(payload)
  } catch (error) {
    console.log(`Error updating participant Mastery details: ${(error as Error).toString()}`)
  }
  // After updating at server, update locally
  const localP = state.value.participants.get(p._id)
  if (localP && participantData) localP.update(participantData)
}

// Get a Participant's Progress and Trackings from server
// id: Participant ID
// trackingtype: Type of tracking to filter for (if needed)
async function fetchParticipantDetails(id: string): Promise<ParticipantData> {
  const payload: APIRequestPayload = {
    method: XHR_REQUEST_TYPE.GET,
    credentials: true,
    route: '/api/participant/details',
    query: { id },
  }
  return apiRequest<ParticipantData>(payload)
}

async function fetchSpecialRequest(
  participantID: string,
  requestType: SPECIAL_REQUEST_TYPE,
  trackingType: TRACKING_TYPE,
): Promise<SpecialRequestData> {
  const payload: APIRequestPayload = {
    method: XHR_REQUEST_TYPE.GET,
    credentials: true,
    route: '/api/tracking/special',
    query: { participantID, requestType, trackingType },
  }
  return apiRequest<SpecialRequestData>(payload)
}

// Sent currently loaded Trackings to the server if not already sent
// We can only send trackings owned by the current user, as we must know the Participant IDs
async function sendTrackings(): Promise<void> {
  function loadTrackingAttachment(path: string[], fileName: string): Promise<BlobPart | void> {
    const cd: CordovaData = new CordovaData({
      fileName,
      readFile: true,
      asText: false,
      asJSON: false,
      path,
    })
    return deviceActions.loadFromStorage<BlobPart>(cd).then((blob) => blob)
  }

  if (!deviceGetters.deviceOnline.value) return Promise.resolve()

  // Iterate over all tracking selecting those that are not marked 'serverSynced'
  const it = state.value.trackings.values()
  let t = it.next()

  // Using async-await, multiple tracking posts should be sent in series
  while (!t.done) {
    const tracking: Tracking = t.value
    if (!tracking.serverSynced) {
      const path = [CordovaPathName.participants, tracking.participantID]
      let trackingAudioFile
      let trackingVideoFile
      if (tracking.audioFile) trackingAudioFile = await loadTrackingAttachment(path, tracking.audioFile)
      if (tracking.videoFile) trackingVideoFile = await loadTrackingAttachment(path, tracking.videoFile)

      const formData = new FormData()

      const data = JSON.stringify(tracking.asPOJO())
      formData.append('data', data)

      if (trackingAudioFile) {
        const blob = new Blob([trackingAudioFile], { type: 'audio/mp4' })
        formData.append('audio', blob)
      }
      if (trackingVideoFile) {
        const blob = new Blob([trackingVideoFile], { type: 'video/mp4' })
        formData.append('video', blob)
      }

      const payload: APIRequestPayload = {
        method: XHR_REQUEST_TYPE.POST,
        credentials: true,
        route: '/api/tracking',
        body: formData,
        contentType: XHR_CONTENT_TYPE.MULTIPART,
      }

      // Wait for the request to return and see that it succeeded
      let trackingData
      try {
        trackingData = await apiRequest<TrackingData>(payload)
      } catch (error) {
        console.log(`Error posting tracking data: ${(error as Error).toString()}`)
      }
      if (trackingData) tracking.serverSynced = !!trackingData.serverSynced
      else console.log(`Tracking POST failed! Tracking ID: ${tracking.itemID}`)
    }
    t = it.next()
  }
  return actions.saveTrackings()
}

// ------------  Getters (Read only / Immutable)! --------------
interface Getters {
  selectedParticipant: ComputedRef<Participant>
  selectedLocation: string
  locations: string[]
  participants: ComputedRef<Participant[]>
}
const getters = {
  get selectedParticipant(): ComputedRef<Participant> {
    return computed(() => state.value.selectedParticipant)
  },
  get selectedLocation(): string {
    return ref(state.value.selectedLocation).value
  },
  get locations(): string[] {
    return reactive(state.value.locations)
  },
  get participants(): ComputedRef<Participant[]> {
    return computed(() => Array.from(state.value.participants.values()))
  },
}
// ------------  Actions --------------
interface Actions {
  selectParticipant: (participant: Participant) => void
  selectLocation: (location: string) => void
  setParticipants: (participants: Participant[]) => void
  setParticipantAvatar: (layout: AvatarLayout, name: string) => void
  commitNewTracking: (tracking: Tracking) => void

  // Server
  getParticipants: () => Promise<void>
  getParticipantDetails: (id: string) => Promise<Participant>
  getSpecialRequest: (participantID: string, requestType: SPECIAL_REQUEST_TYPE, trackingType: TRACKING_TYPE) => Promise<SpecialRequestData>
  getLocations: () => Promise<void>
  syncParticipant: (updateAll?: boolean, participant?: Participant) => Promise<void>
  updateParticipantMastery: (p: Participant) => Promise<void>
  sendTrackings: () => Promise<void>
  addParticipant: (locationName: string) => Promise<Participant>

  // Disk
  loadParticipants: (myUser: User) => Promise<void>
  saveParticipants: (updateAll: boolean) => Promise<void>
  loadTrackings: (myUser: User) => Promise<void>
  saveTrackings: () => Promise<void>
}

const actions = {
  // Reference to a participant in state.value.userParticipants
  selectParticipant: function (participant: Participant): void {
    state.value.selectedParticipant.selected = false
    participant.selected = true
    state.value.selectedParticipant = participant
    // Place file data (e.g. recordings) for this Participant inside: participants/<participantID>/
    deviceActions.setCordovaPath([CordovaPathName.participants, participant._id])
  },
  selectLocation: function (location: string): void {
    if (location) {
      state.value.selectedLocation = location
    }
  },
  // Replace the current list of participants with another
  setParticipants: function (participants: Participant[]): void {
    state.value.participants.clear()
    participants.forEach((p: Participant) => {
      state.value.participants.set(p._id, p)
    })
  },
  setLocations(locations: string[]): void {
    state.value.locations.splice(0)
    Object.values(locations).forEach((l) => state.value.locations.push(l))
  },
  // Get Details for the currently selected Participant
  // Sets them in the store and also returns them to the component
  getParticipantDetails: async function (id: string): Promise<Participant> {
    const response: ParticipantData = await fetchParticipantDetails(id)
    // This includes Progress information
    const participant = new Participant(response)
    return participant
  },
  // Call for speical response data for use in mastery / visuals / ets
  getSpecialRequest: async function (
    participantID: string,
    requestType: SPECIAL_REQUEST_TYPE,
    trackingType: TRACKING_TYPE,
  ): Promise<SpecialRequestData> {
    const response: SpecialRequestData = await fetchSpecialRequest(participantID, requestType, trackingType)
    return { participant: response.participant, data: response.data }
  },
  // Add a new Tracking for this participant
  commitNewTracking: function (tracking: Tracking): void {
    state.value.trackings.set(tracking.oid, tracking)
  },
  setParticipantAvatar: function (layout: AvatarLayout, name: string): void {
    const pToUpdate = state.value.participants.get(state.value.selectedParticipant._id)
    if (pToUpdate) {
      pToUpdate.profile.avatar = layout
      pToUpdate.profile.name = name
    }
  },

  // -------------   Server activities -----------------

  // Retrieve the current user's participants from server
  async getParticipants(): Promise<void> {
    const payload: APIRequestPayload = {
      method: XHR_REQUEST_TYPE.GET,
      credentials: true,
      query: {},
      route: '/api/participants',
    }
    return apiRequest<ParticipantData[]>(payload).then((response: ParticipantData[]) => {
      const ps: Participant[] = response.map((p) => new Participant(p))
      actions.setParticipants(ps)
    })
  },

  // Fetch the current list of locations
  getLocations(): Promise<void> {
    const payload: APIRequestPayload = {
      method: XHR_REQUEST_TYPE.GET,
      credentials: true,
      route: '/api/participant/locations',
    }
    return apiRequest<string[]>(payload).then((response: string[]) => actions.setLocations(response))
  },

  // Save updated data to server and disk including synchronising Progress
  // Given participant, selected participant, or all Participants if updateAll == true
  // After the server response, local participant is updated by syncParticipants()
  syncParticipant(updateAll = false, participant?: Participant): Promise<void> {
    let ps: Participant[] = []
    if (updateAll) ps = Array.from(state.value.participants.values())
    else if (participant) ps.push(participant)
    else if (state.value.selectedParticipant) ps.push(state.value.selectedParticipant)
    if (deviceGetters.deviceOnline) {
      return syncParticipants(ps).then(() => this.saveParticipants(updateAll))
    }
    return Promise.resolve()
  },

  // Update Mastery details ONLY for a Participant at the server
  updateParticipantMastery(p: Participant): Promise<void> {
    return updateParticipantMastery(p)
  },

  async addParticipant(): Promise<Participant> {
    const data: ParticipantData = {}
    const pData: ParticipantData = await sendAddParticipanttoServer(data)
    const newP = new Participant(pData)
    state.value.participants.set(newP._id, newP)
    return Promise.resolve(newP)
  },
  sendTrackings,

  // -------------   Disk activities -----------------

  // Load participant JSON files based on Participant IDs stored in User model
  loadParticipants: async function (myUser: User): Promise<void> {
    const cd: CordovaData = new CordovaData({
      fileName: 'participant.json',
      readFile: true,
      asText: true,
      asJSON: true,
    })

    for (const p of myUser.participants) {
      cd.path = [CordovaPathName.participants, p._id]
      await deviceActions.loadFromStorage<ParticipantData>(cd).then((data) => {
        if (data) {
          const d = new Participant(data)
          // Overwrite any matching server-downloaded Participants
          // Intending to sync with server properly in next stage
          state.value.participants.set(d._id, d)
        }
      })
    }
    return Promise.resolve()
  },
  saveParticipants: async function (updateAll: boolean): Promise<void> {
    // Collect Participants to be saved as regular objects
    let ps: Participant[] = []
    if (updateAll) ps = Array.from(state.value.participants.values())
    else if (state.value.selectedParticipant) ps = [state.value.selectedParticipant]

    // Save each Participant to its own subdirectory
    for (const p of ps) {
      const data = p.asPOJO()
      const cd: CordovaData = new CordovaData({
        fileName: 'participant.json',
        data,
        asText: true,
        asJSON: true,
        path: [CordovaPathName.participants, p._id],
      })
      await deviceActions.saveToStorage(cd)
    }
    return Promise.resolve()
  },
  // Load tracking from each Participant folder owned by this User, merge them into the store
  loadTrackings: async function (myUser: User): Promise<void> {
    const cd: CordovaData = new CordovaData({
      fileName: 'trackings.json',
      readFile: true,
      asText: true,
      asJSON: true,
    })
    state.value.trackings.clear()
    for (const p of myUser.participants) {
      cd.path = [CordovaPathName.participants, p._id]
      const data = await deviceActions.loadFromStorage<TrackingData[]>(cd)
      if (data) {
        data.forEach((tracking) => {
          if (tracking.oid) state.value.trackings.set(tracking.oid, new Tracking(tracking))
        })
      }
    }
    return Promise.resolve()
  },
  saveTrackings: async function (): Promise<void> {
    // Convert Map to Object keyed by Participant ID
    const trackingsByParticipant: Record<string, unknown[]> = {}
    state.value.trackings.forEach((t) => {
      if (!trackingsByParticipant[t.participantID]) trackingsByParticipant[t.participantID] = []
      trackingsByParticipant[t.participantID].push(t.asPOJO())
    })
    // Save each list of trackings to their Participant's folder
    for (const [pID, trackings] of Object.entries(trackingsByParticipant)) {
      const cd: CordovaData = new CordovaData({
        fileName: 'trackings.json',
        data: trackings,
        asText: true,
        asJSON: true,
        path: [CordovaPathName.participants, pID],
      })
      await deviceActions.saveToStorage(cd)
    }
  },
}
// This defines the interface used externally
interface ServiceInterface {
  actions: Actions
  getters: Getters
  state: Ref<State>
}
export function useParticipantStore(): ServiceInterface {
  return {
    getters,
    actions,
    state,
  }
}

export type ParticipantStoreType = ReturnType<typeof useParticipantStore>
