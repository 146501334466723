import moment from 'moment'
import { uuid, getRoleCode, noWardNeeded } from '@/utilities'
import { USER_ROLE, taskColours, USER_KIND, BrowserLanguageCodes, SquidexLanguageCodes, BLCToSLC } from '@/constants'

// ---------------  Utility -----------------
declare global {
  interface String {
    toPascalCase(): string
    toCamelCase(): string
    padZero(length: number): string
  }
  interface MediaFile {
    localURL: string
  }
}
String.prototype.toPascalCase = function () {
  const text = this.valueOf().replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''))
  return text.substring(0, 1).toUpperCase() + text.substr(1)
}

String.prototype.toCamelCase = function () {
  const text = this.valueOf().replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''))
  return text.substring(0, 1).toLowerCase() + text.substr(1)
}

String.prototype.padZero = function (length: number): string {
  let s = String(this)
  while (s.length < length) {
    s = '0' + s
  }
  return s
}

type LC<T> = {
  [key in SquidexLanguageCodes]: T
}

interface Dictionary<T> {
  [Key: string]: T
}

type EnumDictionary<T extends string | symbol | number, U> = {
  [K in T]: U
}

export type { Dictionary, EnumDictionary }

// ---------------  Models -----------------

export interface Callback<T, U> {
  (arg?: T): U
}
export interface LocalUser extends Record<string, unknown> {
  _id: string
  jwt: string
  lastLogin: Date
  pin: string
  name: string
  selected: boolean
}
// General App settings that should be saved to disk
export interface PersistedAppState extends Record<string, unknown> {
  localUsers: Record<string, LocalUser>
}

// ------------ Cordova interface -------------

export interface CordovaDataType {
  readFile?: boolean // Returns the content if true, returns a FileEntry if false  (read)
  asText?: boolean // false   Set to true if reading a text or JSON file, otherwise binary will be used  (read/write)
  asJSON?: boolean // true   Set to false to read/write a file without parsing or stringifying JSON  (read/write)
  overwrite?: boolean // false   Set to true to overwrite an existing file (open file)
  append?: boolean // false   Set to true to append data to the end of the file (write)
  path?: string[] // Path to the file below the root as an array of directory names (read/write)
  fileName?: string // name of the file on disk (read/write)
  data?: unknown | Blob | string // the content to be written  (write)
  file?: FileEntry // the FileEntry object in memory
  fileToMove?: FileEntry | MediaFile // the file entry object in momory for the file to be moved
}
export class CordovaData {
  readFile = false
  asText = false
  asJSON = true
  overwrite = false
  append = false
  path: string[]
  fileName = ''
  data?: unknown | string | Blob
  file?: FileEntry
  fileToMove?: /* data */ FileEntry | MediaFile /* video */

  constructor(data: CordovaDataType) {
    this.path = []
    if (data) {
      this.readFile = data.readFile ? data.readFile : false
      this.asText = data.asText ? data.asText : false
      this.asJSON = data.asJSON ? data.asJSON : true
      this.overwrite = data.overwrite ? data.overwrite : false
      this.append = data.append ? data.append : false
      this.path = data.path ? data.path : []
      this.fileName = data.fileName ? data.fileName : ''
      this.data = data.data
      this.file = data.file
      this.fileToMove = data.fileToMove
    }
  }
}

// ---------------  Base CMS model classes ------------------
// --- Extend these to represent real Squidex model types ---

export enum DISPLAY_MODE {
  linear = 'linear',
  shuffle = 'shuffle',
}

export enum TRACKING_TYPE {
  interaction = 'interaction',
  all = 'all',
}

//
// ---------------  Server Data --------------
//

// TrackingData will store information about a 'usage'
export interface TrackingData {
  itemID: string // ID of the associated question or picturebook etc. (from Squidex CMS)
  participantID: string // ID of the associated Participant
  type: string

  // All other items are optional
  oid?: string // Unique key used to map this item
  created?: Date | string // should mark the start of the tracking
  duration?: number // should mark the end of the tracking in seconds, starting from 'created'
  audioFile?: string
  videoFile?: string
  details?: Record<string, unknown> // Holds any kind of extra data needed for the Tracking

  localSynced?: boolean // saved to disk locally
  serverSynced?: boolean // saved to our server
  storageSynced?: boolean // saved to TSD
}

export class Tracking {
  itemID: string // ID of the associated question, set or picturebook etc. (from Squidex CMS)
  participantID: string // ID of the associated Participant
  type: TRACKING_TYPE

  oid: string // Unique key used to map this item
  created: Date // the start of the tracking
  duration: number // should mark the end of the tracking in seconds, starting from 'created'
  audioFile?: string
  videoFile?: string
  details?: Record<string, unknown> // Holds any kind of data specific to the question type

  // Status
  localSynced: boolean // saved to disk locally
  serverSynced: boolean // saved to our server successfully
  storageSynced: boolean // sent to TSD successfully

  constructor(trackingdata: TrackingData) {
    this.itemID = trackingdata.itemID
    this.participantID = trackingdata.participantID
    this.type = trackingdata.type as TRACKING_TYPE
    this.oid = trackingdata.oid ? trackingdata.oid : uuid()
    this.created = trackingdata.created ? new Date(trackingdata.created) : new Date()
    this.duration = trackingdata.duration ? trackingdata.duration : 0

    // Optional
    this.audioFile = trackingdata.audioFile
    this.videoFile = trackingdata.videoFile

    this.oid = trackingdata.oid ? trackingdata.oid : uuid()
    this.created = trackingdata.created ? new Date(trackingdata.created) : new Date()

    this.localSynced = !!trackingdata.localSynced
    this.serverSynced = !!trackingdata.serverSynced
    this.storageSynced = !!trackingdata.storageSynced
  }

  // Complete this Tracking by setting its duration and possibly 'data'
  complete(): void {
    const startDate = moment(this.created)
    const endDate = moment()
    this.duration = endDate.diff(startDate, 'seconds')
  }

  // Convert this to a Plain Old Javascript Object
  asPOJO(): unknown {
    const pojo = { ...this }
    return pojo
  }
}
export interface SpecialRequestData {
  participant: ParticipantData
  data: Record<string, Record<number, { total: number; correct: number }>>
}

export enum SPECIAL_REQUEST_TYPE {
  successresults = 'successresults',
}

// ********** TABLES ***************
export interface Kindergarten {
  kindergartenName: string
  district: string
  districtType: string
  districtID: string
  kindergartenID: string

  wards: Ward[]
}

export interface ColumnDef {
  headerName: string
  field: string

  children?: {
    field: string
    headerName: string
    columnGroupShow?: string
    // eslint-disable-next-line
    [x: string]: any
  }[]
  hide?: boolean | unknown
  editable?: boolean | unknown
  // eslint-disable-next-line
  [x: string]: any
}
export abstract class Table {
  _id: string
  constructor(id?: string) {
    this._id = id ? id : ''
  }
  abstract update(
    data: Ward | WardData | User | UserData | Participant | ParticipantData | Nudge | NudgeData | NudgeSchemaData,
    language: BrowserLanguageCodes,
  ): void
  static columnDefs(): ColumnDef[] {
    return []
  }
}

/* Nudge classes, interfaces and dependencies */

export enum DataContent {
  barnehage = 'barnehage',
  avdeling = 'avdeling',
  barn = 'barn',
  selvrapportering = 'selvrapportering',
}

export enum ActionType {
  NETTSKJEMA = 'nettskjema',
  RECORD = 'audioopptak',
  TASK = 'oppgave',
  INFO = 'info',
}

export enum AssetType {
  Video = 'Video',
  Audio = 'Audio',
  Image = 'Image',
}

export enum NudgeStatus {
  NO_RESPONSE = 'No response',
  RESPONDED = 'Responded',
  NOT_SENT = 'Not sent',
  SENT = 'Sent',
  PARTIALLY_SENT = 'Partially sent',
}

export interface NudgeSchemaData {
  id: string
  tittel?: LC<string>
  instruksjon?: LC<string>
  assetType?: LC<AssetType> | null // iv
  assetURL: LC<[{ url: string }] | null> // iv
  om: LC<DataContent> // required // iv
  filterAgeFrom?: LC<number> // iv
  filterAgeTo?: LC<number> // iv
  datainnsamler: LC<USER_KIND> // required
  handling: LC<ActionType> // required // iv
  oppgaver?: LC<string> // iv
  nettskjemaURL?: LC<string>
  response?: LC<boolean> // required // iv
  reminder?: LC<string>
  frist?: LC<string> // date string
}

interface NudgeSchemaFields {
  title: string
  instruction: string
  dataContent: DataContent
  dataCollector: string
  actionType: ActionType
  nettskjemaURL: string
  receiverSign: boolean
  noticeText: string
  deadline: Date | string
  noticeAllTexts: string
}

export const convertSchemaKindToUserKind = (value: string): string => {
  switch (value) {
    case 'forskningsassistent':
      return USER_KIND.forskningsassistent
    case 'forsker':
      return USER_KIND.forsker
    case 'styrere':
      return USER_KIND.styrer
    case 'mentorer':
      return USER_KIND.mentor
    case 'ansatte':
      return USER_KIND.ansatt
    case 'pedledere':
      return USER_KIND.pedleder
    case 'foresatte':
      return USER_KIND.foresatt
    default:
      return ''
  }
  return ''
}
export class NudgeSchema extends Table {
  title: string
  instruction: string
  assetType: AssetType | undefined
  assetURL: string
  dataContent: DataContent
  dataCollector: string
  actionType: ActionType
  // nettskjemaURL: string
  nettskjemaURL: string
  receiverSign: boolean
  noticeText: string
  deadline: Date | string
  oppgaver: string

  constructor(schema: NudgeSchemaData, language: BrowserLanguageCodes) {
    super(schema ? schema.id : '')
    this.title = ''
    this.instruction = ''
    this.dataContent = DataContent.avdeling
    this.dataCollector = ''
    this.assetType = undefined

    this.assetURL = ''
    this.actionType = ActionType.INFO
    this.nettskjemaURL = ''
    this.receiverSign = false
    this.noticeText = ''
    this.deadline = ''
    this.oppgaver = ''
    this.update(schema, language)
  }

  update(schema: NudgeSchemaData, l: BrowserLanguageCodes): void {
    const language = BLCToSLC[l] as SquidexLanguageCodes
    const nudgeSchema = schema

    this.title = nudgeSchema.tittel?.[language] || ''
    this.instruction = nudgeSchema.instruksjon?.[language] || ''
    this.dataContent = nudgeSchema.om.iv || ''
    this.dataCollector = convertSchemaKindToUserKind(nudgeSchema.datainnsamler.iv) || ''
    this.assetType = nudgeSchema.assetType?.iv || undefined

    this.assetURL = nudgeSchema.assetURL?.iv?.length ? nudgeSchema.assetURL.iv[0].url : ''
    this.actionType = nudgeSchema.handling.iv || ''
    /* this.nettskjemaURL = nudgeSchema.handlingURL
      ? nudgeSchema.handlingURL.iv
      : ''*/
    this.nettskjemaURL = nudgeSchema.nettskjemaURL?.[language] || ''
    this.receiverSign = !!nudgeSchema.response?.iv
    this.noticeText = nudgeSchema.reminder?.[language] || ''
    this.deadline = nudgeSchema.frist?.iv ? new Date(nudgeSchema.frist.iv) : ''
    this.oppgaver = nudgeSchema.oppgaver?.iv ? nudgeSchema.oppgaver.iv : ''
  }

  public static columnDefs(): ColumnDef[] {
    return [
      { headerName: 'Nudge', field: 'title' },
      { headerName: 'Who', field: 'dataCollector' },
      { headerName: 'Registered', field: 'dataContent' },
      { headerName: 'Active', field: 'active', editable: true },
      {
        headerName: 'Selection (Data collector)',
        field: 'selectionUsers',
        editable: true,
      },
      {
        headerName: 'Selection (Children)',
        field: 'selectionParticipants',
        editable: true,
      },
      {
        headerName: 'Selection (Group)',
        field: 'selectionGroup',
        editable: true,
      },
      { headerName: 'All', field: 'all', editable: true },
      { headerName: 'Date sent', field: 'dateSent' },
      { headerName: 'reminderSent', field: 'reminderSent' },
      { headerName: 'Total Responders', field: 'totalResponded' },
      { headerName: 'NoHeaderName', field: 'sendNudge' },
      { headerName: 'Stage', field: 'stage', editable: true },
    ]
  }

  public get asTableData(): NudgeSchemaFields {
    return {
      title: this.title || '(title missing)',
      instruction: this.instruction || '(instruction missing)',
      dataCollector: this.dataCollector,
      dataContent: this.dataContent,
      actionType: this.actionType,
      nettskjemaURL: this.nettskjemaURL || 'URL text missing',
      receiverSign: this.receiverSign,
      noticeText: this.noticeText || 'Norwegian notice text missing',
      deadline: this.deadline,
      noticeAllTexts: this.noticeText,
    }
  }
}

export interface NudgeData {
  _id: string
  squidexID: string

  // Database
  dbRefs: {
    creator: string
    users: Array<string> // A list of users who have gotten a Nudge
  }
  created: Date // Date stamp when this nudge was created
  creator: string
  active: boolean
  selectionUsers: string
  selectionParticipants: string
  selectionGroup: string
  all: boolean
  dateSent: Date | undefined
  reminderSent: Date | undefined
  totalResponded: number

  list: Array<string> // List can be dynamic, it reflects which users are currently reflected
  children: Map<string, string>
  dateSentDictionary: Record<string, string>
  stage: string
}

export interface NudgeTableLayout {
  id: string
  squidexID: string
  active: boolean
  selectionUsers: string
  selectionParticipants: string
  selectionGroup: string
  all: boolean
  dateSent: string
  reminderSent: string
  totalResponded: number
  sentList: Array<string>
  list: Array<string>
  children: Map<string, string>
  dateSentDictionary: Map<string, string>
  stage: string
}

interface NudgeServerData {
  id: string
  active: boolean
  selectionUsers: string
  selectionParticipants: string
  selectionGroup: string
  all: boolean
  list: Array<string>
  children: Map<string, string>
  stage: string
}

export interface NudgeMetadata {
  _id: string
  nudge: UserNudge
  respondant: NudgeMetadataRespondant
  ward: NudgeMetadataWard
  participant: NudgeMetadataGenerel
  status: NudgeStatus
  created: string
  isDeleted: boolean

  kindergarten: {
    districtID: string
    kindergartenID: string
    district: string
    kindergartenName: string
  }

  audioData?: Blob // Used only for sending audio data to server
  type?: ActionType // Used only for sending data type to server
}

export interface SendNudgeResponse {
  nudge: NudgeData
  nudgeMetadata: NudgeMetadata[]
}

export interface UserNudge {
  _id: string
  squidexID: string
}

export interface NudgeMetadataGenerel {
  _id: string
  skRef: string
  name: string
}

export interface NudgeMetadataRespondant extends NudgeMetadataGenerel {
  kind: USER_KIND
}

export interface NudgeMetadataWard extends NudgeMetadataGenerel {
  wardID: string
  kindergartenID: string
  districtID: string
}

export class Nudge extends Table {
  squidexID: string

  // Data which can be updated through the nudge table
  created: Date | string
  creator: string
  active: boolean
  selectionUsers: string
  selectionParticipants: string
  selectionGroup: string
  all: boolean
  dateSent: Date | ''
  reminderSent: Date | ''
  totalResponded: number
  dbRefs: {
    creator: string
    users: Array<string> // A list of users who have gotten a Nudge
  }
  list: Array<string>
  children: Map<string, string>
  noticeText?: string
  dateSentDictionary: Map<string, string>
  stage: string

  constructor(data?: NudgeData | Nudge) {
    super(data ? data._id : '')
    this.squidexID = ''
    this.created = ''

    this.creator = ''
    this.active = false
    this.selectionUsers = ''
    this.selectionParticipants = ''
    this.selectionGroup = ''
    this.all = false
    this.dateSent = ''
    this.reminderSent = ''
    this.totalResponded = 0
    this.dbRefs = {
      creator: '',
      users: new Array<string>(),
    }
    this.list = new Array<string>()
    this.children = new Map<string, string>()
    this.dateSentDictionary = new Map<string, string>()
    this.stage = ''
    if (data) this.update(data)
    else console.error('No data when initializing Nudge')
  }

  update(data: NudgeData | Nudge): void {
    this.squidexID = data.squidexID

    this.created = data.created
    this.creator = data.creator
    this.active = data.active || false
    this.selectionUsers = data.selectionUsers
    this.selectionParticipants = data.selectionParticipants
    this.selectionGroup = data.selectionGroup
    this.all = data.all
    this.dateSent = data.dateSent || ''
    this.reminderSent = data.reminderSent || ''
    this.totalResponded = data.totalResponded
    this.dbRefs = data.dbRefs || {
      creator: '',
      users: new Array<string>(),
    }
    this.list = data.list || []
    this.children = data.children || new Map<string, string>()
    if (data.dateSentDictionary) {
      if (data.dateSentDictionary instanceof Map) this.dateSentDictionary = data.dateSentDictionary
      else this.dateSentDictionary = new Map(Object.entries(data.dateSentDictionary))
    }
    this.stage = data.stage || ''
  }

  public static columnDefs(): ColumnDef[] {
    return [
      { headerName: 'Lock', field: 'lock' },
      { headerName: 'Nudge', field: 'title' },
      { headerName: 'Who', field: 'dataCollector' },
      { headerName: 'Registered', field: 'dataContent' },
      { headerName: 'Active', field: 'active', editable: true },
      {
        headerName: 'Selection (Data collector)',
        field: 'selectionUsers',
        editable: true,
      },
      {
        headerName: 'Selection (Children)',
        field: 'selectionParticipants',
        editable: true,
      },
      {
        headerName: 'Selection (Group)',
        field: 'selectionGroup',
        editable: true,
      },
      // { headerName: 'All', field: 'all', editable: true },
      { headerName: 'Date sent', field: 'dateSent' },
      { headerName: 'reminderSent', field: 'reminderSent' },
      { headerName: 'Total Responders', field: 'totalResponded' },
      { headerName: '', field: 'sendNudge' },
      { headerName: 'Stage', field: 'stage', editable: true },
    ]
  }

  // Use to get data for a Table
  public get asTableData(): NudgeTableLayout {
    return {
      id: this._id,
      squidexID: this.squidexID,
      active: this.active,
      selectionUsers: this.selectionUsers,
      selectionParticipants: this.selectionParticipants,
      selectionGroup: this.selectionGroup,
      all: this.all,
      dateSent: this.dateSent.toString(),
      reminderSent: this.reminderSent.toString(),
      totalResponded: this.totalResponded,
      sentList: this.dbRefs.users,
      list: this.list,
      children: this.children,
      dateSentDictionary: this.dateSentDictionary,
      stage: this.stage,
    }
  }

  // Use to set data from a Table
  public set fromTableData(data: NudgeTableLayout) {
    this.active = data.active
    this.selectionUsers = data.selectionUsers
    this.selectionParticipants = data.selectionParticipants
    this.selectionGroup = data.selectionGroup
    this.all = data.all
    this.stage = data.stage
  }

  // Process the Nudge class to update only the necessary parts.
  public get asServerData(): NudgeServerData {
    return {
      id: this._id,
      active: this.active,
      selectionUsers: this.selectionUsers,
      selectionParticipants: this.selectionParticipants,
      selectionGroup: this.selectionGroup,
      all: this.all,
      list: this.list,
      children: this.children,
      stage: this.stage,
    }
  }
}

export interface ParticipantTableLayout {
  skSelf: string
  name: string
  birthday: string
  status: string
  gender: string
  skWard: {
    db: string
    sk: string
  }
  skUser1: {
    db: string
    sk: string
  }
  skUser2: {
    db: string
    sk: string
  }
  creationDate?: Date
  id: string
  t3: Array<boolean>
  t2: Array<boolean>
  t1: Array<boolean>
  comments: string
}

export interface ParticipantData {
  _id?: string
  consents?: {
    id?: string
    state?: string
  }

  dbRefs?: {
    ward: string
    user1: string
    user2: string
    creator: string // Employer or researcher who created this Particicpant
  }
  skRefs?: {
    self: string // 'Scrambling key' reference for this record
    ward: string // 'Scrambling key' reference to ward record
    user1: string
    user2: string
  }
  status?: string
  profile?: {
    ref?: string
    name?: string
    birthday: string
    gender: string
    thumbnail?: string
    colour?: string
    avatar?: AvatarLayout
  }
  created?: Date
  progress?: Array<boolean>
  t2Progress?: Array<boolean>
  t3Progress?: Array<boolean>
  comments?: string
}
export class Participant extends Table {
  dbRefs: {
    ward: string
    user1: string
    user2: string
    creator: string // Employer or researcher who created this Particicpant
  }
  skRefs: {
    self: string // 'Scrambling key' reference for this record
    ward: string // 'Scrambling key' reference to ward record
    user1: string
    user2: string
  }
  consents: {
    state: string
    id: string
  }
  profile: {
    ref: string
    name: string
    birthday: string
    gender: string
    avatar: AvatarLayout
    colour: string
    thumbnail: string
  }

  created: Date | undefined
  progress: Array<boolean> // Keep track of a child's progress
  t2Progress: Array<boolean>
  t3Progress: Array<boolean>
  comments = ''
  status = 'active'
  // Front end control
  selected = false
  lid: string // Local id set on creation, not used at back-end

  updateData(data: ParticipantData | Participant): void {
    if (data.created) this.created = data.created
    if (data.dbRefs) this.dbRefs = data.dbRefs
    if (data.skRefs) this.skRefs = data.skRefs
    if (data.status) this.status = data.status
    if (data.profile) {
      this.profile.ref = data.profile.ref ? data.profile.ref : ''
      this.profile.thumbnail = data.profile.thumbnail ? data.profile.thumbnail : ''
      this.profile.birthday = data.profile.birthday || ''
      this.profile.gender = data.profile.gender || ''
      this.profile.colour = data.profile.colour || taskColours[Math.floor(Math.random() * taskColours.length)]
      this.profile.name = data.profile.name ? data.profile.name : ''
      if (data.profile.avatar) {
        this.profile.avatar = {
          eyeShape: data.profile.avatar.eyeShape || '0',
          eyeColour: data.profile.avatar.eyeColour || '#000000',
          hairShape: data.profile.avatar.hairShape || '13',
          hairColour: data.profile.avatar.hairColour || '#010300',
          skinColour: data.profile.avatar.skinColour || '#EFD5CE',
          noseShape: data.profile.avatar.noseShape || '2',
          lipColour: data.profile.avatar.lipColour || '#000000',
          accessories: data.profile.avatar.accessories || '13',
        }
      }
    }
    if (data.consents) {
      this.consents.state = data.consents.state ? data.consents.state : ''
      this.consents.id = data.consents.id ? data.consents.id : ''
    }
  }

  constructor(data?: ParticipantData | Participant) {
    super(data ? data._id : '')
    this.lid = uuid()
    this.dbRefs = {
      ward: '',
      user1: '',
      user2: '',
      creator: '',
    }
    this.skRefs = {
      self: '',
      user1: '',
      user2: '',
      ward: '',
    }
    this.consents = {
      id: '',
      state: '',
    }
    this.profile = {
      ref: '',
      name: '',
      colour: '',
      birthday: '',
      gender: '',
      thumbnail: '',
      avatar: {
        eyeShape: '0',
        eyeColour: '#000000',
        hairShape: '13',
        hairColour: '#010300',
        skinColour: '#EFD5CE',
        noseShape: '2',
        lipColour: '#000000',
        accessories: '13',
      },
    }

    this.progress = []
    if (data && data.progress) {
      this.progress = data.progress
      if (this.progress.length > 2 || this.progress.length == 0) this.progress = [false, false]
    }

    this.t2Progress = []
    if (data && data.t2Progress) {
      this.t2Progress = data.t2Progress
      if (this.t2Progress.length > 2 || this.t2Progress.length == 0) this.t2Progress = [false, false]
    }

    this.t3Progress = []
    if (data && data.t3Progress) {
      this.t3Progress = data.t3Progress
      if (this.t3Progress.length > 2 || this.t3Progress.length == 0) this.t3Progress = [false, false]
    }

    if (data && data.profile) this.update(data)
    if (data && data.comments) this.comments = data.comments
  }

  public static columnDefs(): ColumnDef[] {
    return [
      {
        headerName: 'T1',
        field: 't1',
        children: [
          {
            field: 'T1_phase4',
            headerName: 'T1_phase4',
            columnGroupShow: 'close',
            hide: true,
          },
          {
            field: 'T1_phase5',
            headerName: 'T1_phase5',
            columnGroupShow: 'open',
            hide: true,
          },
          {
            field: 'T1_phase1',
            headerName: 'T1_phase1',
            columnGroupShow: 'close',
            hide: true,
            editable: false,
          },
          {
            field: 'T1_phase2',
            headerName: 'T1_phase2',
            columnGroupShow: 'open',
            hide: true,
            editable: false,
          },
          {
            field: 'T1_phase3',
            headerName: 'T1_phase3',
            columnGroupShow: 'open',
            hide: true,
            editable: false,
          },
        ],
        hide: true,
      },
      {
        headerName: 'T2',
        field: 't2',
        children: [
          {
            field: 'T2_phase4',
            headerName: 'T2_phase4',
            columnGroupShow: 'open',
          },
          {
            field: 'T2_phase5',
            headerName: 'T2_phase5',
            columnGroupShow: 'open',
          },
          {
            field: 'T2_phase1',
            headerName: 'T2_phase1',
            columnGroupShow: 'close',
            editable: false,
          },
          {
            field: 'T2_phase2',
            headerName: 'T2_phase2',
            columnGroupShow: 'open',
            editable: false,
          },
          {
            field: 'T2_phase3',
            headerName: 'T2_phase3',
            columnGroupShow: 'open',
            editable: false,
          },
        ],
      },
      {
        headerName: 'T3',
        field: 't3',
        children: [
          {
            field: 'T3_phase4',
            headerName: 'T3_phase4',
            columnGroupShow: 'open',
          },
          {
            field: 'T3_phase5',
            headerName: 'T3_phase5',
            columnGroupShow: 'open',
          },
          {
            field: 'T3_phase1',
            headerName: 'T3_phase1',
            columnGroupShow: 'close',
            editable: false,
          },
          {
            field: 'T3_phase2',
            headerName: 'T3_phase2',
            columnGroupShow: 'open',
            editable: false,
          },
          {
            field: 'T3_phase3',
            headerName: 'T3_phase3',
            columnGroupShow: 'open',
            editable: false,
          },
        ],
      },
      { headerName: 'ID', field: 'skSelf' },
      { headerName: 'Name', field: 'name' },
      { headerName: 'Birthday', field: 'birthday' },
      { headerName: 'Active', field: 'status', editable: true },
      {
        headerName: 'KindergartenName',
        field: 'kindergartenname',
        editable: false,
      },
      { headerName: 'WardName', field: 'wardname', editable: false },
      { headerName: 'District', field: 'district' },
      { headerName: 'Gender', field: 'gender' },
      { headerName: 'Ward ID', field: 'skWard' },
      { headerName: 'Parent1 ID', field: 'skUser1' },
      { headerName: 'Parent2 ID', field: 'skUser2' },
      {
        headerName: 'NativeLanguage',
        field: 'nativeLanguage',
        editable: false,
      },
      { headerName: 'Creation Date', field: 'creationDate', editable: false },
      { headerName: 'Comments', field: 'comments', editable: true },
      { headerName: 'Group', field: 'group', editable: false },
    ]
  }

  // Use to get data for a Table
  public get asTableData(): ParticipantTableLayout {
    return {
      skSelf: this.skRefs.self,
      name: this.profile.name,
      birthday: this.profile.birthday,
      status: this.status,
      gender: this.profile.gender,
      skWard: {
        db: this.dbRefs.ward,
        sk: this.skRefs.ward,
      },
      skUser1: {
        db: this.dbRefs.user1,
        sk: this.skRefs.user1,
      },
      skUser2: {
        db: this.dbRefs.user2,
        sk: this.skRefs.user2,
      },
      creationDate: this.created,
      id: this._id,

      t1: this.progress,
      t2: this.t2Progress,
      t3: this.t3Progress,
      comments: this.comments,
    }
  }

  // Use to set data from a Table
  public set fromTableData(data: ParticipantTableLayout) {
    this.skRefs.self = data.skSelf
    this.status = data.status
    this.profile.name = data.name
    this.profile.birthday = data.birthday
    this.profile.gender = data.gender
    this.skRefs.ward = data.skWard.sk
    this.skRefs.user1 = data.skUser1.sk
    this.skRefs.user2 = data.skUser2.sk
    this.dbRefs.ward = data.skWard.db
    this.dbRefs.user1 = data.skUser1.db
    this.dbRefs.user2 = data.skUser2.db

    this.comments = data.comments
    this.progress = data.t1
    this.t2Progress = data.t2
    this.t3Progress = data.t3
  }

  // Convert this to a Plain Old Javascript Object
  asPOJO(): unknown {
    const pojo = { ...this }
    return pojo
  }

  // Server response or Monitor update
  update(data: ParticipantData | Participant): void {
    this.updateData(data)
  }

  public getID(): string {
    const userSk = this.skRefs.self
    const wardSk = this.skRefs.ward
    return `${wardSk}-${1}${userSk}`
  }
}

export interface UserData {
  _id: string
  verified?: Date
  lastLogin?: Date
  message?: string
  lastReminder?: Date

  dbRefs: {
    ward: string
    creator: string // Employer or researcher who created this Particicpant
  }
  skRefs?: {
    self: string // 'Scrambling key' reference for this record
    ward: string // 'Scrambling key' reference to ward record
    kindergartenIDs: Array<string> // kindergarten ids
  }
  profile: {
    mobileNumber: string
    password: string
    fullName: string
    birthday: string
    email: string
    kind: string
    role: string
    language: string
    nativeLanguage: string
    isFeide: boolean
    hasPassword: boolean
  }
  consents?: UserConsent[]

  // Needed at front end to retain compatibility with the SLPlus design..
  participants: ParticipantData[]
  nudges: NudgeMetadata[]
  comments: string

  // isActive: boolean
  status: string
}

interface TableLayout {
  id: string
  kind: string
  fullName: string
  mobileNumber: string
  message: string
  comments?: string
}
export interface UserTableLayout extends TableLayout {
  combinedSK: string
  skSelf: string
  skWard: {
    db: string
    sk: string
  }
  kdGarten: string[]
  isFeide: boolean
  hasPassword: boolean
  password: string
  language: BrowserLanguageCodes // Pop-up preset values
  verified?: Date
  lastLogin?: Date
  totalConsents: number
  consented: boolean
  status: string

  // Enrollment variables
  birthday: string
  email: string
  nativeLanguage: string
  comments: string

  kdGartenID?: string
}

export interface ManagementTableLayout extends TableLayout {
  status: string
  children: string
  lastReminder: Date | undefined
}

export interface UserConsent {
  method: string
  consented: boolean
  ref: string
  date?: Date
}
export class User extends Table {
  verified?: Date
  lastLogin?: Date
  message: string
  lastReminder?: Date

  dbRefs: {
    ward: string
    creator: string // Employer or researcher who created this Particicpant
  } = { ward: '', creator: '' }
  skRefs: {
    self: string // 'Scrambling key' reference for this record
    ward: string // 'Scrambling key' reference to ward record
    kindergartenIDs: Array<string>
  } = { self: '', ward: '', kindergartenIDs: [] }
  profile: {
    mobileNumber: string
    password: string
    fullName: string
    birthday: string
    email: string
    kind: USER_KIND
    role: USER_ROLE
    language: BrowserLanguageCodes // Use a two letter code as the browser does
    nativeLanguage: string
    isFeide: boolean // READ ONLY server status info
    hasPassword: boolean
  } = {
    mobileNumber: '',
    password: '',
    fullName: '',
    email: '',
    birthday: '',
    kind: USER_KIND.foresatt,
    role: USER_ROLE.user,
    language: BrowserLanguageCodes['nb-NO'], // Use a two letter code as the browser does
    nativeLanguage: 'Other',
    isFeide: false,
    hasPassword: false,
  }

  // Consents can be multiple, we should use a separate table for them
  consents: UserConsent[] = []

  // Needed at front end to retain compatibility with the SLPlus design..
  // If posted to server with 'create' Server will attempt to create Participants
  participants: Participant[] = []

  // nudges: Array<NudgeMetadata> = [] // FIXME: This appears to be unused?
  comments = ''
  // isActive = true
  status = 'active'

  constructor(data?: UserData | User) {
    super(data ? data._id : '')
    this.message = ''
    if (data) {
      this.update(data)
      this.profile.isFeide = !!data.profile.isFeide // READ ONLY - not updated by server
      this.profile.hasPassword = !!data.profile.hasPassword // READ ONLY - not updated by server
    }
  }

  public static columnDefs(): ColumnDef[] {
    return [
      { headerName: 'Index', field: 'index', editable: false },
      { headerName: 'Active', field: 'status', editable: true },
      { headerName: 'ID', field: 'skSelf', editable: false },
      { headerName: 'User Kind', field: 'kind', editable: true },
      { headerName: 'Full Name', field: 'fullName' },
      { headerName: 'Kindergarten', field: 'kdGarten', editable: false },
      { headerName: 'WardName', field: 'wardName', editable: false },
      { headerName: 'District', field: 'district', editable: false },
      { headerName: 'Consents', field: 'totalConsents', editable: false },
      { headerName: 'Mobile Number', field: 'mobileNumber' },
      { headerName: 'Email', field: 'email' },
      { headerName: 'Native Language', field: 'nativeLanguage' },
      { headerName: 'Birthday', field: 'birthday' },
      { headerName: 'Ward ID', field: 'skWard' },
      { headerName: 'Kindergarten ID', field: 'kdGartenID' },
      { headerName: 'Passphrase', field: 'password' },
      { headerName: 'Language', field: 'language' },
      { headerName: 'Verified', field: 'verified', editable: false },
      { headerName: 'Last Login', field: 'lastLogin', editable: false },
      { headerName: 'FEIDE User', field: 'isFeide', editable: false },
      { headerName: 'Message', field: 'message', editable: false },
      { headerName: 'Comments', field: 'comments', editable: true },
      { headerName: 'Group', field: 'group', editable: false },
    ]
  }

  // Use to get data for a Table
  public get asTableData(): UserTableLayout {
    return {
      combinedSK: this.getID(), //Fully combined sk keys of ward+role+skself
      skSelf: this.skRefs.self,
      skWard: {
        db: this.dbRefs.ward,
        sk: this.skRefs.ward,
      },
      kdGarten: this.skRefs.kindergartenIDs,
      kind: this.profile.kind,
      fullName: this.profile.fullName,
      mobileNumber: this.profile.mobileNumber,
      password: this.profile.password,
      language: this.profile.language,
      verified: this.verified,
      lastLogin: this.lastLogin,
      isFeide: this.profile.isFeide,
      id: this._id,
      totalConsents: this.consents.filter((c) => c.consented).length,
      consented: this.consented(),
      hasPassword: this.profile.hasPassword,
      message: this.message,
      birthday: this.profile.birthday,
      email: this.profile.email,
      nativeLanguage: this.profile.nativeLanguage,
      comments: this.comments,
      status: this.status,
    }
  }

  // Get user data for Management table(pedleder and styrer)
  public get asManagementTableData(): ManagementTableLayout {
    const getStatus = () => {
      if (this.verified) {
        if (this.consents.length > 0) return 'OK'
        else {
          return 'No consents'
        }
      } else {
        return 'Not validated'
      }
    }

    const getChildren = () => {
      const children = this.participants
      if (children) return children.map((child) => child.profile.name).join(', ')
      return ''
    }

    return {
      id: this._id,
      kind: this.profile.kind,
      fullName: this.profile.fullName,
      mobileNumber: this.profile.mobileNumber,
      message: this.message,
      status: getStatus(),
      children: getChildren(),
      lastReminder: this.lastReminder,
    }
  }

  public set fromManagementTableData(data: { mobileNumber: string; password: string }) {
    this.profile.mobileNumber = data.mobileNumber
    this.profile.password = data.password
  }

  // Use to set data from a Table
  public set fromTableData(data: UserTableLayout) {
    this.skRefs.self = data.skSelf
    this.skRefs.ward = data.skWard.sk

    if (this.profile.kind == USER_KIND.styrer && data.kdGartenID) this.skRefs.kindergartenIDs = data.kdGartenID.split(',')
    if (this.profile.kind == USER_KIND.forskningsassistent && data.kdGartenID) this.skRefs.kindergartenIDs = data.kdGartenID.split(',')
    this.dbRefs.ward = data.skWard.db
    this.profile.kind = data.kind as USER_KIND
    this.profile.fullName = data.fullName
    this.profile.mobileNumber = data.mobileNumber
    this.profile.password = data.password
    this.profile.language = data.language

    // Extra info for enrollment
    this.profile.birthday = data.birthday
    this.profile.email = data.email
    this.profile.nativeLanguage = data.nativeLanguage

    /* this.profile.isFeide = data.isFeide */
    this.verified = data.verified
    this.lastLogin = data.lastLogin
    this.comments = data.comments
    this.status = data.status
  }

  // --------  User Consent functions replicating Table functions ----------

  // Gets a *LIST* of consents for this User
  public get getAllConsents(): UserConsent[] {
    return this.consents
  }
  // Set a new consent from Consent Table data (row)
  public createOneConsent(consent: UserConsent): void {
    this.consents.push(consent)
  }
  // Update an existing consent from Consent Table data (row)
  public updateOneConsent(consent: UserConsent): void {
    const cUpdate = this.consents.find((c) => c.ref === consent.ref)
    if (cUpdate) {
      cUpdate.method = consent.method
      cUpdate.date = consent.date
      cUpdate.ref = consent.ref
    }
  }
  // Delete a consent from Consent Table data (row)
  public deleteOneConsent(consent: UserConsent): void {
    const i = this.consents.findIndex((c) => c.ref === consent.ref)
    if (i > -1) this.consents.splice(i, 1)
  }

  // Check if all the consents are consented
  public consented(): boolean {
    if (this.consents.length == 0) return false
    const consents = this.consents
    switch (this.profile.kind) {
      case USER_KIND.forskningsassistent:
      case USER_KIND.styrer:
      case USER_KIND.pedleder:
      case USER_KIND.foresatt:
        return consents.length == 2 && consents.every((c) => c.consented == true)
      case USER_KIND.ansatt:
      case USER_KIND.mentor:
        return consents.length == 1 && consents.every((c) => c.consented == true)
      case USER_KIND.forsker:
        return true
      default:
        break
    }
    return false
  }

  public clear(): void {
    this._id = ''
    this.dbRefs = {
      ward: '',
      creator: '',
    }
    this.skRefs = {
      self: '',
      ward: '',
      kindergartenIDs: [],
    }
    this.profile = {
      mobileNumber: '',
      password: '',
      fullName: '',
      email: '',
      birthday: '',
      kind: USER_KIND.foresatt,
      role: USER_ROLE.user,
      language: BrowserLanguageCodes['nb-NO'],
      nativeLanguage: '',
      isFeide: false,
      hasPassword: false,
    }
    this.consents = []
    this.participants = []
  }

  public update(data: UserData | User): void {
    this._id = data._id
    this.verified = data.verified
    this.lastLogin = data.lastLogin ? new Date(data.lastLogin) : undefined
    this.lastReminder = data.lastReminder ? new Date(data.lastReminder) : undefined
    this.message = data.message || ''

    if (data.dbRefs) this.dbRefs = data.dbRefs
    if (data.skRefs) this.skRefs = data.skRefs

    this.profile = {
      mobileNumber: data.profile.mobileNumber,
      password: data.profile.password,
      fullName: data.profile.fullName,
      email: data.profile.email,
      birthday: data.profile.birthday,
      kind: (data.profile.kind as USER_KIND) || USER_KIND.foresatt,
      role: (data.profile.role as USER_ROLE) || USER_ROLE.user,
      language: (data.profile.language as BrowserLanguageCodes) || BrowserLanguageCodes['nb-NO'],
      nativeLanguage: data.profile.nativeLanguage || 'Other',
      isFeide: this.profile.isFeide, // READ ONLY - not updated by server
      hasPassword: data.profile.hasPassword, // READ ONLY - not updated by server
    }

    if (data.consents) {
      this.consents = data.consents
    }

    this.participants = []
    if (data.participants) {
      data.participants.forEach((p: Participant | ParticipantData | string) => {
        let newParticipant
        if (p instanceof Participant) newParticipant = p
        else if (typeof p === 'string') newParticipant = new Participant({ _id: p })
        else newParticipant = new Participant(p)
        this.participants.push(newParticipant)
      })
    }

    // this.nudges = data.nudges || [] // FIXME: Appears to be unused?
    this.comments = data.comments || ''
    // this.isActive = data.isActive
    this.status = data.status || 'active'
  }

  // Convert this to a Plain Old Javascript Object
  asPOJO(): unknown {
    const participants: unknown[] = []
    if (this.participants) {
      const paArray = Array.from(this.participants.values())
      paArray.forEach((p) => {
        participants.push(p.asPOJO())
      })
    }
    const pojo = { ...this, participants }
    return pojo
  }

  public getID(): string {
    const kind = this.profile.kind
    const userSk = this.skRefs.self
    const wardSk = this.skRefs.ward
    const ward = !noWardNeeded(kind) && wardSk ? wardSk : '0-000-0000-00'
    return `${ward}-${getRoleCode(kind)}${userSk}`
  }

  // Get a NudgeMetadata from User if it exists
  // FIXME: Function appears to be unused?
  /* public getNudge(id: string): NudgeMetadata | undefined {
    return this.nudges.find((n) => {
      return n.nudge && n.nudge._id == id
    })
  } */
}

export interface WardTableLayout {
  id: string
  skSelf: string
  wardName: string
  kindergartenName: string
  district: string
  districtType: string
  districtID: string
  kindergartenID: string
  wardTypeID: string
  comments: string
  group: string
  t3: Array<boolean>
  t2: Array<boolean>
  t1: Array<boolean>
}
export interface WardData {
  _id: string
  created: Date
  skRef: string
  wardName: string
  kindergartenName: string
  district: string
  districtType: string
  districtID: string
  kindergartenID: string
  wardID: string
  wardTypeID: string
  progress: Array<boolean>
  t2Progress: Array<boolean>
  t3Progress: Array<boolean>
  comments: string
  group: string
}

export class Ward extends Table {
  created: Date
  skRef: string
  wardName: string
  kindergartenName: string
  district: string
  districtType: string
  districtID: string
  kindergartenID: string
  wardID: string
  wardTypeID: string
  progress: Array<boolean>
  t2Progress: Array<boolean>
  t3Progress: Array<boolean>
  comments: string
  group: string

  constructor(data: WardData | Ward) {
    super(data._id || '')
    this.skRef = ''
    this.wardName = ''
    this.kindergartenName = ''
    this.district = ''
    this.districtType = ''
    this.districtID = ''
    this.kindergartenID = ''
    this.wardID = ''
    this.wardTypeID = ''
    this.progress = []
    this.t2Progress = []
    this.t3Progress = []
    this.created = new Date()
    this.comments = ''
    this.group = ''

    this.update(data)
  }

  update(data: WardData | Ward): void {
    this.created = new Date(data.created)
    this.skRef = data.skRef || ''
    this.wardName = data.wardName || ''
    this.kindergartenName = data.kindergartenName || ''
    this.district = data.district || ''
    this.districtType = data.districtType || ''
    this.districtID = data.districtID || ''
    this.kindergartenID = data.kindergartenID || ''
    this.wardID = data.wardID || ''
    this.wardTypeID = data.wardTypeID || ''
    this.group = data.group || ''

    this.progress = data.progress || [false, false, false, false]
    this.t2Progress = data.t2Progress || [false, true, false, true]
    this.t3Progress = data.t3Progress || [false, true, false, true]

    if (this.progress.length < 4) this.progress.push(false) // Compatibility
    if (this.t2Progress.length < 4) this.t2Progress.push(false) // Compatibility
    if (this.t3Progress.length < 4) this.t3Progress.push(false) // Compatibility
  }

  public static columnDefs(): ColumnDef[] {
    return [
      { headerName: 'ID', field: 'skSelf', editable: true },
      { headerName: 'Ward Name', field: 'wardName', editable: true },
      {
        headerName: 'Kindergarten Name',
        field: 'kindergartenName',
        editable: true,
      },
      { headerName: 'District', field: 'district', editable: true },
      { headerName: 'Children', field: 'children', editable: false },
      { headerName: 'District Type', field: 'districtType', editable: true },
      { headerName: 'Kindergarten ID', field: 'kindergartenID' },
      { headerName: 'Comments', field: 'comments', editable: true },
      {
        headerName: 'Group',
        field: 'group',
        editable: true,
      },
      {
        headerName: 'T1',
        field: 't1',
        children: [
          {
            field: 'T1_phase1',
            headerName: 'T1_phase1',
            columnGroupShow: 'close',
            hide: true,
            editable: false,
          },
          {
            field: 'T1_phase2',
            headerName: 'T1_phase2',
            columnGroupShow: 'open',
            hide: true,
            editable: false,
          },
          {
            field: 'T1_phase3',
            headerName: 'T1_phase3',
            columnGroupShow: 'open',
            hide: true,
            editable: false,
          },
          {
            field: 'T1_phase4',
            headerName: 'T1_phase4',
            columnGroupShow: 'open',
            hide: true,
            editable: false,
          },
        ],
        editable: false,
        hide: true,
      },
      {
        headerName: 'T2',
        field: 't2',
        children: [
          {
            field: 'T2_phase1',
            headerName: 'T2_phase1',
            columnGroupShow: 'open',
          },
          {
            field: 'T2_phase2',
            headerName: 'T2_phase2',
            columnGroupShow: 'open',
          },
          {
            field: 'T2_phase3',
            headerName: 'T2_phase3',
            columnGroupShow: 'open',
          },
          {
            field: 'T2_phase4',
            headerName: 'T2_phase4',
            columnGroupShow: 'open',
          },
        ],
        editable: true,
      },
      {
        headerName: 'T3',
        field: 't3',
        children: [
          {
            field: 'T3_phase1',
            headerName: 'T3_phase1',
            columnGroupShow: 'open',
          },
          {
            field: 'T3_phase2',
            headerName: 'T3_phase2',
            columnGroupShow: 'open',
          },
          {
            field: 'T3_phase3',
            headerName: 'T3_phase3',
            columnGroupShow: 'open',
          },
          {
            field: 'T3_phase4',
            headerName: 'T3_phase4',
            columnGroupShow: 'open',
          },
        ],
        editable: true,
      },
    ]
  }

  // Use to get data for a Table
  public get asTableData(): WardTableLayout {
    return {
      id: this._id,
      skSelf: this.skRef,
      wardName: this.wardName,
      kindergartenName: this.kindergartenName,
      district: this.district,
      districtType: this.districtType,
      districtID: this.districtID,
      kindergartenID: this.kindergartenID,
      wardTypeID: this.wardTypeID,
      comments: this.comments,
      group: this.group,
      t1: this.progress,
      t2: this.t2Progress,
      t3: this.t3Progress,
    }
  }

  // Use to set data from a Table
  public set fromTableData(data: WardTableLayout) {
    this.skRef = data.skSelf
    this.wardName = data.wardName
    this.kindergartenName = data.kindergartenName
    this.district = data.district
    this.districtType = data.districtType
    this.districtID = data.districtID
    this.kindergartenID = data.kindergartenID
    this.wardTypeID = data.wardTypeID
    this.progress = data.t1
    this.t2Progress = data.t2
    this.t3Progress = data.t3
    this.comments = data.comments
    this.group = data.group
  }
}

// -------------- Other UI Types ---------------
export interface AvatarLayout {
  eyeShape: string
  eyeColour: string
  hairShape: string
  hairColour: string
  skinColour: string
  noseShape: string
  lipColour: string
  accessories: string
}

//
// ---------------  Squidex Data --------------
//

export interface ConsentFormData {
  userKind: LC<string>
  content: LC<string>
  consents: LC<[{ consent: string; ref: string }]>
}
export interface ConsentRecord {
  description: string
  ref: string
  given: boolean
  hasConsented: boolean
}
export class ConsentForm {
  userKind: string
  content: string
  consents: ConsentRecord[]

  constructor(data: ConsentFormData, l: BrowserLanguageCodes) {
    const language = BLCToSLC[l] as SquidexLanguageCodes
    this.userKind = data.userKind.iv
    this.content = data.content[language] ? data.content[language] : ''
    this.consents = data.consents[language]
      ? data.consents[language].map((item) => ({
          description: item.consent,
          ref: item.ref,
          given: false,
          hasConsented: false,
        }))
      : []
  }
}

export interface LandingPageData {
  data: {
    content: {
      [key: string]: string
    }
  }
}

export class LandingPage {
  content: string

  constructor(data: LandingPageData, languageCode: BrowserLanguageCodes) {
    this.content = data.data.content[languageCode] ? data.data.content[languageCode] : ''
  }
}

export interface CommonCMS {
  data: {
    id: {
      iv: string
    }
    text: Record<string, string>
  }
}
export class Common {
  dictionary: Map<string, Record<string, string>>

  constructor(spec: Array<CommonCMS>) {
    const commons = spec
    this.dictionary = new Map<string, Record<string, string>>()
    commons.forEach((c) => {
      this.dictionary.set(c.data.id.iv, c.data.text)
    })
  }

  getWordSentence(word: string, lang: string): string {
    const wordSentence = this.dictionary.get(word)
    let translatedWordSentence = ''
    if (wordSentence) {
      translatedWordSentence = wordSentence[lang]
    }
    return translatedWordSentence ? translatedWordSentence : word
  }
}

// ----------  Squidex response shapes --------------

// Data level of a Squidex GraphQL response. Can be supplied as single or array
// Extend this interface to represent different responses for various Sett and Question types
export interface CmsGQLData {
  __typename?: string
  id?: string
  flatData?: unknown
  data?: unknown
}
// Shape of the Sett -> Question response
export interface CmsQuestionData extends CmsGQLData {
  flatData: {
    questions: CmsGQLData[]
  }
}

// Top level of a Squidex GrapghQL response
export interface CmsGQLQuery {
  data?: {
    results: CmsGQLData[] | CmsGQLData | CmsQuestionData
  }
  errors?: []
  access_token?: string
}

// ---------------  API -----------------

enum XHR_REQUEST_TYPE {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  DELETE = 'DELETE',
}

enum XHR_CONTENT_TYPE {
  JSON = 'application/json',
  MULTIPART = 'multipart/form-data',
  URLENCODED = 'application/x-www-form-urlencoded',
}

interface APIRequestPayload {
  method: XHR_REQUEST_TYPE
  route: string
  credentials?: boolean
  body?: unknown | string | User | Participant | ParticipantData | TrackingData | FormData
  headers?: Record<string, string>
  query?: Record<string, string>
  contentType?: string
  baseURL?: string
}

// Augment the Error class with message and status
class HttpException extends Error {
  status: number
  message: string
  constructor(status: number, message: string) {
    super(message)
    this.status = status
    this.message = message
  }
}

interface XHRError {
  status: number
}

interface XHRPayload {
  url: string
  headers: Record<string, string>
  credentials: boolean
  body: string | FormData
  method: XHR_REQUEST_TYPE
}

export type { APIRequestPayload, XHRPayload, XHRError }
export { XHR_REQUEST_TYPE, HttpException, XHR_CONTENT_TYPE }
