/*
 Copyright 2018 Richard Nesnass
*/

const theHost = import.meta.env.VITE_SERVER_HOST as string
const thePort = import.meta.env.VITE_SERVER_PORT as string
let baseUrl = `${theHost}`
console.log(baseUrl)
/*if (import.meta.env.NODE_ENV === 'development') {
  baseUrl = `${theHost}:${thePort}`
}*/
// Local development hosting requires the port for "hot reload"
if (import.meta.env.DEV && theHost && (theHost.includes('localhost') || theHost.includes('10.0.0.') || theHost.includes('192.168.'))) {
  baseUrl = `${theHost}:${thePort}`
}
console.log(baseUrl)
const assetRoot: string = import.meta.env.VITE_CMS_HOST + '/api/assets' || ''
// CMS
const cmsUrl: string = import.meta.env.VITE_CMS_HOST || ''
const cmsClientId: string = import.meta.env.VITE_SQUIDEX_CLIENT_ID || ''
const cmsClientSecret: string = import.meta.env.VITE_SQUIDEX_CLIENT_SECRET || ''
const cmsTokenUrl: string = import.meta.env.VITE_SQUIDEX_TOKEN_URL || ''

const userRolesString = (import.meta.env.VITE_USER_ROLES as string) || ''
const userRoles = userRolesString.split(',')
const userKindsString = (import.meta.env.VITE_USER_KINDS as string) || ''
const userKinds = userKindsString.split(',')
const consentStatesString = (import.meta.env.VITE_CONSENT_STATES as string) || ''
const consentStates = consentStatesString.split(',')

let appVersion: string = document.documentElement.getAttribute('data-appversion') || ''

// The web-based app will always be the latest version, set the version directly from .env
// If not built with Cordova, 'data-appversion' will === '%%VERSION%%'
if (appVersion === '%%VERSION%%') appVersion = import.meta.env.VITE_APP_VERSION || ''

const deviceType: string = window.location.protocol == 'file:' ? 'mobile' : 'web'

enum MediaType {
  audio = 'audio',
  video = 'video',
}
enum CordovaPathName {
  root = '.',
  users = 'users',
  participants = 'participants',
  games = 'games',
}
const cordovaConstants = {
  videoRecordingMaxDuration: 300000, // 5 minutes
  audioRecordingMaxDuration: 300000, // 5 minutes
}

const taskColours = ['#A861A6', '#F84016', '#009F4B', '#A9BD50', '#FFDE01']

// User roles determine what is displayed on screen UI
// FIXME: Edit this enum to match the content of the env file
enum USER_ROLE {
  user = 'user',
  monitor = 'monitor',
  admin = 'admin',
}
// Ensure enums match those defined in env file
const t = Object.values(USER_ROLE)
if (!userRoles.every((e: string) => t.indexOf(e as USER_ROLE) > -1) || userRoles.length !== t.length) {
  console.error('USER_ROLE enum mismatch', { t, userRoles })
}

// User kinds determine what type of user is possible
// FIXME: Edit this enum to match the content of the env file
enum USER_KIND {
  foresatt = 'foresatt',
  ansatt = 'ansatt',
  pedleder = 'pedleder',
  styrer = 'styrer',
  forsker = 'forsker',
  mentor = 'mentor',
  forskningsassistent = 'forskningsassistent',
}
// Ensure enums match those defined in env file
const u = Object.values(USER_KIND)
if (!userKinds.every((e: string) => u.indexOf(e as USER_KIND) > -1) || userKinds.length !== u.length) {
  console.error('USER_KIND enum mismatch', { t, userKinds })
}

enum BrowserLanguageCodes {
  system = 'system', // System default
  ar = 'ar',
  bs = 'bs',
  'nb-NO' = 'nb-NO',
  'nn-NO' = 'nn-NO',
  nb_NO = 'nb_NO',
  en = 'en',
  ku = 'ku',
  pl = 'pl',
  so = 'so',
  ta = 'ta',
  tr = 'tr',
  ur = 'ur',
  /*  sq = 'sq', */
  /*   vi = 'vi', */
}
// Squidex stores language codes using underscore '_' rather than hyphen '-' :-/
export enum SquidexLanguageCodes {
  iv = 'iv', // Invariant code
  ar = 'ar',
  bs = 'bs',
  'nb-NO' = 'nb_NO',
  'nn-NO' = 'nn_NO',
  en = 'en',
  ku = 'ku',
  pl = 'pl',
  so = 'so',
  /*  sq = 'sq', */
  ta = 'ta',
  tr = 'tr',
  ur = 'ur',
  /*   vi = 'vi', */
}
// Convert Browser code to Squidex code
const BLCToSLC: Record<BrowserLanguageCodes, string> = {
  system: 'system', // System default
  ar: 'ar',
  bs: 'bs',
  'nb-NO': 'nb_NO',
  'nn-NO': 'nn_NO',
  nb_NO: 'nb_NO',
  en: 'en',
  ku: 'ku',
  pl: 'pl',
  so: 'so',
  /*  sq = 'sq', */
  ta: 'ta',
  tr: 'tr',
  ur: 'ur',
  /*   vi = 'vi', */
}
enum LanguageNames {
  system = 'system', // System default
  ar = 'العربية', // Arabic
  bs = 'bosanski', // Bosnian
  'nb-NO' = 'Norsk Bokmål',
  'nn-NO' = 'Norsk Nynorsk',
  en = 'English',
  ku = 'Soranî', // Kurdish
  pl = 'Polski', // Polish
  so = 'af Soomaali', // Somali
  /*   sq = 'shqip', // Albanian */
  ta = 'தமிழ்', // Tamil
  tr = 'Türkçe',
  ur = 'اردو', // Urdu
  /*  vi = 'tiếng việt', // Vietnamese */
}

enum LanguageNamesEnglish {
  system = 'system', // System default
  ar = 'Arabisk', // Arabic
  bs = 'Serbisk/kroatisk/bosnisk', // Bosnian
  'nb-NO' = 'Norsk Bokmål',
  'nn-NO' = 'Norsk Nynorsk',
  en = 'Engelsk',
  ku = 'Kurdisk (kurmanji)/Kurdisk (sorani)', // Kurdish
  pl = 'Polsk', // Polish
  so = 'Somali', // Somali
  es = 'Spansk',
  sq = 'Albansk', // Albanian
  ta = 'Tamil', // Tamil
  tr = 'Tyrkisk',
  ur = 'Urdu', // Urdu
  vi = 'Vietnamesisk', // Vietnamese
  fr = 'Fransk',
  ti = 'Tigrinia',
  ru = 'Russisk',
  tl = 'Filippinsk',
}

interface LF {
  [key: string]: Array<BrowserLanguageCodes>
}
const LanguageFallbacks: LF = {
  en: [],
  'nn-NO': [BrowserLanguageCodes['nb-NO'], BrowserLanguageCodes.en],
  'nb-NO': [BrowserLanguageCodes.en],
  nb_NO: [BrowserLanguageCodes['nb-NO'], BrowserLanguageCodes.en],
}

const nativeLanguages = [
  'Albansk',
  'Amharisk',
  'Arabisk',
  'Burmesisk',
  'Dari',
  'Engelsk',
  'Farsi',
  'Filippinsk',
  'Fransk',
  'Kurdisk (kurmanji)/Kurdisk (sorani)',
  'Dansk',
  'Svensk',
  'Hindi',
  'Gresk',
  'Norsk Bokmål',
  'Norsk Nynorsk',
  'Oromo',
  'Pashto',
  'Persisk',
  'Polsk',
  'Portugisisk',
  'Russisk',
  'Samisk',
  'Serbisk/kroatisk/bosnisk',
  'Somali',
  'Spansk',
  'Swahili',
  'Tagalog',
  'Tamil',
  'Thai',
  'Tigrinia',
  'Tsjetsjensk',
  'Tyrkisk',
  'Tysk',
  'Urdu',
  'Vietnamesisk',
  'Annet',
]

enum HamburgerMenuEvent {
  NUDGES = 'nudges',
  ENROLL = 'enroll',
  CONSENT = 'consent',
  MONITOR = 'monitor',
  LOGOUT = 'logout',
}

const emailRegex = new RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
const statusAsArray = ['active', 'not active', 'temporary', 'permanently quit']
export enum STATUS {
  ACTIVE = 'active',
  NOT_ACTIVE = 'not active',
  TEMPORARY = 'temporary',
  PERMANENTLY_QUIT = 'permanently quit',
}
export {
  deviceType,
  baseUrl,
  assetRoot,
  userRoles,
  USER_ROLE,
  userKinds,
  USER_KIND,
  MediaType,
  consentStates,
  cordovaConstants,
  CordovaPathName,
  appVersion,
  taskColours,
  cmsUrl,
  cmsClientId,
  cmsClientSecret,
  cmsTokenUrl,
  BrowserLanguageCodes,
  BLCToSLC,
  LanguageNames,
  LanguageFallbacks,
  LanguageNamesEnglish,
  HamburgerMenuEvent,
  nativeLanguages,
  emailRegex,
  statusAsArray,
}
